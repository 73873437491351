import React, { useState, useEffect, useRef } from 'react'

import { useParams } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import { TableRow, TableCell, IconButton, Collapse, Box, TextField } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'

import TableFilters from '../../filters/TableFilters'

import useStyle from '../../../styles/components/StatisticRowStyle'
import statisticsApi from '../../../api/statistics'
import reportApi from '../../../api/table'
import useHandleError from '../../../api/useHandleError'

const StatisticRow = ({ row, index, tableRows, setTableRows, isDragDisabled, setIsDragDisabled }) => {
  const { id } = useParams()
  const isNewRowBool = row?.isNew || false
  const [inputData, setInputData] = useState({
    name: '',
    count: 0,
    filters: {},
  })
  const [isNewRow, setIsNewRow] = useState(isNewRowBool)
  const [isEditMode, setIsEditMode] = useState(isNewRowBool)
  const [nameValidationError, setNameValidationError] = useState(false)
  const [selectedRow, setSelectedRow] = useState(row?.isNew ? row : null)
  const [filters, setFilters] = useState({})
  const classes = useStyle()

  const handleError = useHandleError()

  const handleChange = (e) => {
    setInputData({ ...inputData, name: e.target.value })
  }

  /* Open/close current row */
  const handleEdit = ({ currentTarget: { name } }) => {
    const clickedRow = tableRows.find((row) => row.id + '' === name)
    setSelectedRow(clickedRow)
    setIsEditMode(!isEditMode)
    setIsDragDisabled(!isEditMode)
    setInputData({
      name: clickedRow.name,
      count: clickedRow.count,
      filters: clickedRow.filters,
    })
    setFilters(clickedRow.filters)
    setNameValidationError(false)
    setIsNewRow(false)
  }

  const handleSave = async (e) => {
    if (!inputData.name) {
      setNameValidationError(true)
      return
    }

    handleEdit(e)

    setSelectedRow(inputData)

    const copiedRows = [...tableRows]
    const clickedRow = copiedRows.find((row) => row.id === selectedRow.id)

    clickedRow.name = inputData.name
    clickedRow.count = inputData.count
    clickedRow.filters = inputData.filters

    if (selectedRow?.isNew) {
      const newFilter = await statisticsApi.addRowsToTile(id, clickedRow).catch(handleError)
      clickedRow.id = newFilter.data.id

      if (typeof clickedRow.order !== 'number') {
        clickedRow.order = copiedRows.length - 1
      }
    } else {
      statisticsApi.updateRow(row.id, clickedRow).catch(handleError)
    }

    clickedRow.isNew = false

    setTableRows(copiedRows)
  }

  const handleDelete = (e) => {
    setTableRows(tableRows.filter((row) => row.id + '' !== e.currentTarget.name))
    if (!row?.isNew) statisticsApi.deleteRow(row.id).catch(handleError)
  }

  /* Deep copies row with new index */
  const handleCopy = async (e) => {
    const selectedRow = tableRows.find((row) => row.id + '' === e.currentTarget.name)
    const copiedRow = { ...selectedRow }

    const copiedRowData = await statisticsApi
      .addRowsToTile(id, copiedRow)
      .then((data) => data.data)
      .catch(handleError)

    copiedRowData.order = tableRows.length

    setTableRows([...tableRows, copiedRowData])
  }

  const setFilteredCount = async () => {
    let getRowsCount = await reportApi
      .getReports(filters)
      .then((data) => {
        if (data.status === 204) {
          return 0
        }

        return data.data.count
      })
      .catch(handleError)
    setInputData({ ...inputData, count: getRowsCount, filters: filters })
  }

  const didMount = useRef(false)
  useEffect(() => {
    setIsDragDisabled(isEditMode)

    if (didMount.current) {
      selectedRow && setFilteredCount()
    }

    didMount.current = true
  }, [filters])

  return (
    <Draggable draggableId={row.id + ''} index={row.order} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <>
          <TableRow
            aria-label={`filter-${index}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
          >
            <TableCell component="th" scope="row" align="center" className={classes.cell}>
              {isEditMode ? (
                <TextField
                  onChange={handleChange}
                  value={inputData.name}
                  className={classes.nameInput}
                  name={row.id + ''}
                  error={nameValidationError}
                  aria-label={`filterName-${index}`}
                  inputProps={{ maxLength: 30 }}
                />
              ) : (
                row.name
              )}
            </TableCell>

            <TableCell align="center" aria-label={`filteredValue-${index}`} className={classes.cell}>
              {isEditMode ? <TextField value={inputData.count} className={classes.nameInput} disabled /> : row.count}
            </TableCell>

            <TableCell align="center" className={classes.cell}>
              <IconButton
                onClick={isEditMode ? handleSave : handleEdit}
                name={row.id}
                aria-label={isEditMode ? `filterSaveButton-${index}` : `filterEditButton-${index}`}
              >
                {isEditMode ? <DoneIcon /> : <EditIcon />}
              </IconButton>

              <IconButton
                onClick={handleDelete}
                name={row.id}
                className={isEditMode ? classes.hiddenIcon : ''}
                aria-label={`filterRemoveButton-${index}`}
              >
                <DeleteForeverIcon />
              </IconButton>

              <IconButton
                onClick={
                  /*
                Current row in edit mode => Saves edited data
                New row => Removes new row
                Row without edit mode => Copies row
              */
                  isEditMode && !isNewRow ? handleEdit : isEditMode && isNewRow ? handleDelete : handleCopy
                }
                name={row.id}
                aria-label={
                  isEditMode && !isNewRow
                    ? `filterCloseButton-${index}`
                    : isEditMode && isNewRow
                    ? `filterRemoveButton-${index}`
                    : `filterCopyButton-${index}`
                }
              >
                {
                  /* Current row in edit mode => Close icon
                New row => Delete icon
                Row without edit mode => Copy icon
              */
                  isEditMode && !isNewRow ? (
                    <CloseIcon />
                  ) : isEditMode && isNewRow ? (
                    <DeleteForeverIcon />
                  ) : (
                    <FileCopyOutlinedIcon />
                  )
                }
              </IconButton>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableCellEdit} colSpan={6}>
              <Collapse in={isEditMode || isNewRow} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <TableFilters setFilters={setFilters} page="statistics" usedFilters={row.filters} />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </Draggable>
  )
}

StatisticRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
  setIsNewRow: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
  setIsDragDisabled: PropTypes.func,
}

export default StatisticRow
