import client from './axios'

const reportApi = {
  getReports: (parameters) => {
    const config = { params: parameters }
    return client.get('data/report/', parameters != null ? config : undefined)
  },

  createReport: (data) => {
    return client.post('data/report/', data)
  },

  filters: () => {
    return client.get('data/report/filters')
  },

  getReport: (id) => {
    return client.get(`data/report/${id}`)
  },

  updateReport: (id, data) => {
    return client.put(`data/report/${id}`, data)
  },

  deleteReport: (id) => {
    return client.delete(`data/report/${id}`)
  },

  getSequences: () => {
    return client.get('data/sequence/')
  },

  createSequence: (data) => {
    return client.post('data/sequence/', data)
  },

  getSequence: (id) => {
    return client.get(`data/sequence/${id}`)
  },

  updateSequence: (id, data) => {
    return client.put(`data/sequence/${id}`, data)
  },

  downloadSequence: (id) => {
    return client.get(`data/export/sequence/${id}`)
  },

  deleteSequence: (id) => {
    return client.delete(`data/sequence/${id}`)
  },

  export: (toExportIds, filters) => {
    return client.get('data/export/csv/', {
      params: {
        ids: toExportIds,
        ...filters,
      },
    })
  },

  exportSAS: (toExportIds, filters) => {
    return client.get('data/export/xml', {
      params: {
        ids: toExportIds,
        ...filters,
      },
    })
  },

  getSettings: () => {
    return client.get('settings/')
  },

  schedulerUpdate: (interval) => {
    return client.post('data/export/xml/scheduler/update', { interval: interval })
  },
}

export default reportApi
