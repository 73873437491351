import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { closeSystemMessage } from '../features/systemMessageSlice'

const SystemMessage = () => {
  const dispatch = useDispatch()
  const systemMessage = useSelector((state) => state.systemMessage.value)

  const handleClose = () => dispatch(closeSystemMessage())

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={systemMessage.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={systemMessage.severity}>
        {systemMessage.message}
      </Alert>
    </Snackbar>
  )
}

export default SystemMessage
