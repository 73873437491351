/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react'

import Loader from 'react-loader-spinner'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Button, OutlinedInput, InputLabel, FormControl, Typography, FormHelperText } from '@material-ui/core'
import ArrowForward from '@material-ui/icons/ArrowForward'

import PasswordInput from '../inputs/PasswordInput'
import { AppSettingsContext } from '../../App'
import { setToken, setRefreshToken } from '../../useToken'
import useRole from '../../useUserRole'
import { login } from '../../api/login'
import useStyles from '../../styles/components/LoginFormStyle'
import { setSystemMessage } from '../../features/systemMessageSlice'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

const LoginForm = () => {
  const [loginData, setLoginData] = useState({ username: '', password: '' })
  const [isVerified, setIsVerified] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    username: '',
    password: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const { setUserRole } = useRole()
  const { setIsLoggedIn } = useContext(AppSettingsContext)
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const handleChange = (e) => {
    const { name, value } = e.target
    setLoginData({
      ...loginData,
      [name]: value,
    })
  }

  const isValidInput = () => {
    let newErrorMessage = { username: '', password: '' }

    newErrorMessage =
      loginData.username === ''
        ? { ...newErrorMessage, username: 'Enter your username' }
        : { ...newErrorMessage, username: '' }

    newErrorMessage =
      loginData.password === ''
        ? { ...newErrorMessage, password: 'Enter your password' }
        : { ...newErrorMessage, password: '' }

    setErrorMessage(newErrorMessage)
    return newErrorMessage.password === '' && newErrorMessage.username === ''
  }

  const handleLogin = async (e) => {
    e.preventDefault()

    if (!isVerified) {
      setSystemMessage('Verify you are not robot first')
      return
    }

    if (isValidInput()) {
      setIsLoading(true)
      await login(loginData.username, loginData.password)
        .then((res) => {
          const userRole = res?.data.role.name
          const userToken = res?.data.access
          const refreshToken = res?.data.refresh

          userRole && setUserRole(userRole)

          if (userToken && refreshToken) {
            setToken(userToken)
            setRefreshToken(refreshToken)
            setIsLoggedIn(true)
            setIsLoading(false)

            userRole === 'admin' ? history.replace('/table') : history.replace('/statistics')
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (error.response) {
            setErrorMessage({ ...errorMessage, password: 'Invalid username or password' })
          } else if (error.request) {
            dispatch(
              setSystemMessage({
                message: 'Server is not responding, please try again later',
                severity: 'error',
              })
            )
          } else {
            dispatch(setSystemMessage({ message: error.message, severity: 'error' }))
          }
        })
    }
  }

  const handleCaptchaVerification = (response) => {
    setIsVerified(true)
  }

  return (
    <form onSubmit={handleLogin} method="POST" className={classes.form}>
      <Typography variant="h1" className={classes.title}>
        Gordon Web App
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-adornment-password">Username</InputLabel>
        <OutlinedInput
          label="Username"
          variant="outlined"
          onChange={handleChange}
          value={loginData.username}
          name="username"
          data-cy="input-login-name"
        />
        {errorMessage.username !== '' && (
          <FormHelperText className={classes.errorMessage} aria-label="usernameValidation">
            {errorMessage.username}
          </FormHelperText>
        )}
      </FormControl>
      <PasswordInput
        handleChange={handleChange}
        name="password"
        value={loginData.password}
        errorMessage={errorMessage.password}
        parentStyle={classes}
        autocomplete={true}
      />
      <Button
        variant="contained"
        className={classes.button}
        endIcon={
          isLoading ? (
            <Loader type="TailSpin" color="white" height={10} width={10} className={classes.loading} />
          ) : (
            <ArrowForward />
          )
        }
        type="submit"
        data-cy="input-login-submit"
      >
        Log in
      </Button>
      <GoogleReCaptcha
        onVerify={(token) => {
          setIsVerified(token && true)
        }}
      />
    </form>
  )
}

export default LoginForm
