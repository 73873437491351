import React, { useState, useEffect, createContext } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'

import { ThemeProvider } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import locale from 'date-fns/locale/en-US'

import LoginPage from './pages/LoginPage'
import { getToken } from './useToken'

import SystemMessage from './components/SystemMessage'
import TablePage from './pages/TablePage'
import Navigation from './components/Navigation'
import UsersPage from './pages/UsersPage'
import StatisticsPage from './pages/StatisticsPage'
import StatisticsEditPage from './pages/StatisticsEditPage'
import StatisticsViewPage from './pages/StatisticsViewPage'
import Footer from './components/Footer'

import theme from './theme'
import history from './history'

export const AppSettingsContext = createContext()

if (locale && locale.options) {
  locale.options.weekStartsOn = 1
}

function App() {
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isBusy, setIsBusy] = useState(true)
  const token = getToken()

  useEffect(() => {
    setIsLoggedIn(token != null)
    setIsBusy(false)
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <ThemeProvider theme={theme}>
        <Router>
          <AppSettingsContext.Provider
            value={{
              isLoggedIn: isLoggedIn,
              setIsLoggedIn: setIsLoggedIn,
              selectedRows: selectedRows,
              setSelectedRows: setSelectedRows,
            }}
          >
            {isBusy ? (
              <CircularProgress />
            ) : (
              (!isLoggedIn && history.location.pathname !== '/login' && history.push('/login')) || (isLoggedIn && history.location.pathname === '/' && history.push('/login'))
            )}
            <Switch>
              <Route path="/login">
                <Navigation />
                <LoginPage />
                <Footer />
              </Route>
              <Route path="/statistics/edit/:id">
                <Navigation />
                <StatisticsEditPage />
                <Footer />
              </Route>
              <Route path="/statistics/edit/">
                <Navigation />
                <StatisticsEditPage />
                <Footer />
              </Route>
              <Route path="/statistics/view/:id">
                <StatisticsViewPage />
              </Route>
              <Route path="/statistics">
                <Navigation />
                <StatisticsPage />
                <Footer />
              </Route>
              <Route path="/table">
                <Navigation />
                <TablePage />
                <Footer />
              </Route>
              <Route path="/users">
                <Navigation />
                <UsersPage />
                <Footer />
              </Route>
            </Switch>
            <SystemMessage />
          </AppSettingsContext.Provider>
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
