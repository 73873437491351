import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 350,
    width: 792,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },
  tableHeader: {
    background: theme.palette.primary.light,
    '& .MuiDataGrid-columnHeaderTitle': {
      color: 'white',
    },
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default useStyles
