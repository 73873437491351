import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { IconButton } from '@material-ui/core'

import useStyle from '../styles/components/LinkButton'

const LinkButton = ({ handleClick = null, url, icon, text = '', ...props }) => {
  const history = useHistory()
  const classes = useStyle()
  const handleRedirect = useCallback(() => history.push(url), [history])

  const handleActions = () => {
    handleClick && handleClick()
    handleRedirect()
  }

  return (
    <>
      {text ? (
        <Button onClick={handleActions} className={classes.root} {...props}>
          {icon && icon}
          <span>{text}</span>
        </Button>
      ) : (
        <IconButton onClick={handleActions} className={classes.root} {...props}>
          {icon}
        </IconButton>
      )}
    </>
  )
}

LinkButton.propTypes = {
  handleClick: PropTypes.func,
  url: PropTypes.string,
  icon: PropTypes.element,
  text: PropTypes.string,
}

export default LinkButton
