import React from 'react'

import PropTypes from 'prop-types'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'

import useStyle from '../../styles/components/ConfirmDialogStyle'

const AlertDialog = ({ isDialogOpen, handleDialog, title, description, children }) => {
  const classes = useStyle()

  return (
    <Dialog
      className={classes.root}
      open={isDialogOpen}
      onClose={handleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      {description && <DialogContent className={classes.content}>{description}</DialogContent>}
      <DialogActions className={classes.actions}>{children}</DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  handleDialog: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired, // dialog's buttons
}

export default AlertDialog
