import React from 'react'

import useStyles from '../styles/pages/UsersPageStyle'
import UsersManagementPanel from '../components/panels/UsersManagementPanel'

const UsersPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <UsersManagementPanel />
      <UsersManagementPanel isGordonUser={true} />
    </div>
  )
}

export default UsersPage
