import { useState } from 'react'

const useUserRole = () => {
  const [userRole, setUserRole] = useState(sessionStorage.getItem('userRole'))

  const saveUserRole = (userRole) => {
    const userRoleLower = userRole.toLowerCase()
    sessionStorage.setItem('userRole', userRoleLower)
    setUserRole(userRoleLower)
  }

  const removeUserRole = () => {
    sessionStorage.removeItem('userRole')
    setUserRole(null)
  }

  return {
    setUserRole: saveUserRole,
    removeUserRole: removeUserRole,
    userRole,
  }
}

export default useUserRole
