import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 5rem - 5rem)',
    boxSizing: 'border-box',
    width: 'var(--viewportWidth)',
    padding: '1.6rem',
  },
  tileName: {
    display: 'block',
    marginBottom: '1.2rem',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))',
  },
}))

export default useStyles
