import React from 'react'

import PropTypes from 'prop-types'

import { Card, CardContent, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import useStyle from '../../styles/components/InfoTileStyle'
import statisticsApi from '../../api/statistics'
import useHandleError from '../../api/useHandleError'

const AddNewTile = ({ tiles, setTiles }) => {
  const classes = useStyle()
  const handleError = useHandleError()

  const createTile = async () => {
    const name = `Tile ${tiles.length + 1}`
    const newTileData = await statisticsApi
      .createTile(name)
      .then((data) => data.data)
      .catch(handleError)
    setTiles([...tiles, newTileData])
    return newTileData
  }

  return (
    <Card className={classes.root} aria-label="addNewTile">
      <CardContent className={classes.cardContent}>
        <IconButton onClick={createTile} aria-label="addTileButton">
          <AddCircleOutlineIcon />
        </IconButton>
      </CardContent>
    </Card>
  )
}

AddNewTile.propTypes = {
  tiles: PropTypes.array,
  setTiles: PropTypes.func,
}

export default AddNewTile
