import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    minHeight: '100vh',
    padding: '1.6rem',
    background: 'rgba(0,0,0,0.05)',
  },
  tileTitle: {
    zIndex: '0',
    position: 'relative',
    top: '-3rem',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    width: 'auto',
    textAlign: 'center',
  },
  tilesGrid: {
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, minmax(30rem, 1fr))',
    gridGap: '1.6rem',
    display: 'grid',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(var(--columnCount), 1fr)',
    },
  },
  columnsCount: {
    marginTop: '0.6rem',
    width: 'fit-content',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}))

export default useStyles
