import React, { useState, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import {
  AutoCompleteFilterInput,
  SelectFilterInput,
  BooleanFilterInput,
  SearchFieldFilterInput,
  DateTimePickerFilterInput,
} from './inputs'

import filters from '../../config/filters.json'
import { saveSessionFilters } from '../../useSessionFilters'

import useStyles from '../../styles/components/TableFiltersStyle'
import reportApi from '../../api/table'
import useHandleError from '../../api/useHandleError'
import { removeSessionFilters } from '../../useSessionFilters'

const TableFilters = ({ setFilters, page = 'table', usedFilters = {} }) => {
  const [selectedValues, setSelectedValues] = useState(usedFilters)
  const [renderedFilters, setRenderedFilters] = useState([])
  const [filterOptions, setFilterOptions] = useState(null)
  const classes = useStyles()
  const handleError = useHandleError()

  /* Handle value of filter input */
  const handleSelectedValues = (e, selectedObject, reason) => {
    let newSelectedValue = {}

    if (selectedObject) {
      /* Passed whole object as new filter with value,
      in case of invalid event (without name or value) */
      newSelectedValue = selectedObject
    } else {
      /* Passed only event, so we have to create filter with value */
      const { name, value } = e.target
      newSelectedValue = { [name]: value }
    }

    const selectedFilterValue = Object.values(newSelectedValue)[0]
    const filterNameToBeCleared = Object.keys(newSelectedValue)[0]

    const selectedFilters = {
      ...selectedValues,
      ...newSelectedValue,
    }

    if (reason === 'clear' || typeof selectedFilterValue === undefined || selectedFilterValue === '') {
      const clearedFilters = { ...selectedValues }
      delete clearedFilters[filterNameToBeCleared]
      page === 'table' && saveSessionFilters(selectedFilters)
      return setSelectedValues(clearedFilters)
    }

    /* Append new filter selected value to others */
    setSelectedValues(selectedFilters)

    page === 'table' && saveSessionFilters(selectedFilters)
  }

  useEffect(() => {
    return () => {
      setSelectedValues([])
      removeSessionFilters()
    }
  }, [])

  const getFilterOptions = () =>
    reportApi
      .filters()
      .then((data) => data.data)
      .catch(handleError)

  const filtersWithValues = usedFilters ? filters.map((item, i) => Object.assign({}, item, usedFilters[i])) : filters

  /* Measure impact with large data */
  const getRenderedFilters = (filterValues) => {
    /* Render filters from database */
    const filters = filtersWithValues.map((filter, index) => {
      /* If filter shouldn't appear on defined page */
      if (!filter.pages.includes(page)) {
        return []
      }

      const filterWidthClass = filter.size === 'wide' ? classes.wideInput : classes.input

      switch (filter.type) {
        case 'datetime':
          return (
            <DateTimePickerFilterInput
              key={index}
              filter={filter}
              classes={classes}
              options={filterValues}
              selectedValues={selectedValues}
              handleSelectedValues={handleSelectedValues}
              isSetToMidnight={filter.field === 'date_to'}
              filterWidthClass={filterWidthClass}
            />
          )

        case 'autocomplete':
          return (
            <AutoCompleteFilterInput
              key={index}
              filter={filter}
              options={filterValues}
              selectedValues={selectedValues}
              handleSelectedValues={handleSelectedValues}
              aria-label={`${filter.field}-autocomplete-filter`}
              filterWidthClass={filterWidthClass}
            />
          )

        case 'select':
          return (
            <SelectFilterInput
              key={index}
              filter={filter}
              filterWidthClass={filterWidthClass}
              options={filterValues}
              selectedValues={selectedValues}
              handleSelectedValues={handleSelectedValues}
              aria-label={`${filter.field}-select-filter`}
            />
          )

        case 'boolean':
          return (
            <BooleanFilterInput
              key={index}
              filter={filter}
              classes={classes}
              selectedValues={selectedValues}
              handleSelectedValues={handleSelectedValues}
              filterWidthClass={filterWidthClass}
            />
          )

        case 'searchField':
          return (
            <SearchFieldFilterInput
              key={index}
              filter={filter}
              selectedValues={selectedValues}
              handleSelectedValues={handleSelectedValues}
              filterWidthClass={filterWidthClass}
            />
          )
      }
    })

    return filters
  }

  const isInitialMount = useRef(true)
  useEffect(async () => {
    const usedFilterOptions = filterOptions ? filterOptions : await getFilterOptions()

    if (isInitialMount.current) {
      setFilterOptions(usedFilterOptions)
    }

    isInitialMount.current = false

    setRenderedFilters(getRenderedFilters(usedFilterOptions))
    setFilters(selectedValues)
  }, [selectedValues])

  useEffect(() => {
    const isClearedSelectedValues = Object.keys(usedFilters).length === 0

    if (isClearedSelectedValues && usedFilters !== selectedValues && !isInitialMount.current) {
      setSelectedValues({})
    }
  }, [usedFilters])

  return (
    <section className={`${classes.root} sticky-vertical ${page === 'table' && 'viewportWidth'}`}>
      {renderedFilters}
    </section>
  )
}

TableFilters.propTypes = {
  rows: PropTypes.array,
  setFilters: PropTypes.func,
  page: PropTypes.string,
  usedFilters: PropTypes.object,
}

export default TableFilters
