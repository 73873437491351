import React, { useState, useEffect, createContext, useRef, useContext } from 'react'

import { Divider } from '@material-ui/core'

import TableFilters from '../components/filters/TableFilters'
import DataTable from '../components/tables/DataTable'
import TableActionsInput from '../components/inputs/TableActionsInput'
import reportApi from '../api/table'
import useHandleError from '../api/useHandleError'
import { getSessionFilters } from '../useSessionFilters'

import { AppSettingsContext } from '../App'

export const RowsContext = createContext()

const TablePage = () => {
  const sessionFilters = getSessionFilters() || {}
  const { setSelectedRows, selectedRows } = useContext(AppSettingsContext)

  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [isResultEmpty, setIsResultEmpty] = useState(false)
  const [filters, setFilters] = useState(sessionFilters)
  const [currentPage, setCurrentPage] = useState(null)
  const [maxRowsCount, setMaxRowsCount] = useState(null)
  const [isRefreshed, setIsRefreshed] = useState(false)
  const handleError = useHandleError()

  const getRows = async (usedFilters) => {
    setIsLoading(true)
    const params = { ...usedFilters, page: currentPage }
    /* Preload two pages at the start */
    if (currentPage === 2) {
      let secondPage = []
      const firstPage = await reportApi
        .getReports({ ...usedFilters, page: 1 })
        .then((data) => {
          if (data.status === 204) {
            setIsResultEmpty(true)
            return {
              count: 0,
              next: null,
              previous: null,
              results: [],
            }
          }
          setIsResultEmpty(false)
          return data.data
        })
        .catch(handleError)

      if (firstPage?.next) {
        secondPage = await reportApi
          .getReports(params)
          .then((data) => data.data)
          .catch(handleError)
        const firstTwoPagesRows = [...firstPage.results, ...secondPage.results]
        setIsLoading(false)
        return { ...secondPage, results: firstTwoPagesRows }
      }

      setIsLoading(false)
      return firstPage
    }

    setIsLoading(false)
    return reportApi
      .getReports(params)
      .then((data) => data.data)
      .catch(handleError)
  }

  useEffect(async () => {
    if (currentPage) {
      const res = await getRows(filters)
      if (res) {
        const { results, count } = res
        const rowsData = currentPage === 2 ? results : [...rows, ...results]

        setMaxRowsCount(count)
        setRows(rowsData)
      }
      setIsLoading(false)
    }
  }, [currentPage, isRefreshed])

  const getNewData = async () => {
    setCurrentPage(2)
    setIsRefreshed(!isRefreshed)
    setSelectedRows(new Set())
    setIsLoading(false)
  }

  const previousFilters = useRef(sessionFilters)
  useEffect(() => {
    const hasFiltersChanged = JSON.stringify(previousFilters.current) !== JSON.stringify(filters)

    if (hasFiltersChanged) {
      setSelectedRows(new Set())
    }

    if (currentPage === 2 && hasFiltersChanged) {
      getNewData()
    } else {
      setCurrentPage(2)
      setRows([])
    }
    previousFilters.current = filters
  }, [filters])

  useEffect(() => {
    return () => {
      setSelectedRows(new Set())
    }
  }, [])

  return (
    <RowsContext.Provider
      value={{
        selectedRows,
        setSelectedRows,
        rows,
        setRows,
        isResultEmpty,
        setIsLoading,
        isLoading,
        currentPage,
        setCurrentPage,
        maxRowsCount,
        filters,
        getNewData,
        setFilters,
      }}
    >
      <TableFilters setFilters={setFilters} usedFilters={filters} />
      <Divider />
      <TableActionsInput />
      <DataTable />
    </RowsContext.Provider>
  )
}

export default TablePage
