import client from './axios'

const gordonUsersApi = {
  list: (page) => {
    const config = { params: { page: page } }
    return client.get('user/gordon/', config)
  },

  add: (username, password, role_id) => {
    return client.post('user/gordon/', { username, password, role_id })
  },

  edit: (userId, username, password, role_id) => {
    return client.put(`user/gordon/${userId}/`, { username, password, role_id })
  },

  delete: (userId) => {
    return client.delete(`user/gordon/${userId}/`)
  },

  export: () => {
    return client.get('user/gordon/export/')
  },

  roles: () => {
    return client.get('user/gordon/roles/')
  },
}

export default gordonUsersApi
