import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '2rem',
    fontSize: '2.2rem',
    fontWeight: 'bold',
  },
  form: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    margin: '1rem',
    borderRadius: '1rem',
    boxShadow: '0 0 1rem 1px rgba(0,0,0,0.1)',
    width: '100%',
    maxWidth: '26rem',
    padding: '3rem 3rem 4rem 3rem',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      padding: '2rem ',
    },
  },
  formControl: {
    marginBottom: '0.6rem',
    width: '100%',
  },
  button: {
    boxShadow: 'none',
    width: '100%',
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  errorMessage: {
    color: 'red',
  },
  loading: {
    marginLeft: '10px',
    fontSize: 'inherit !important',
  },
  recaptcha: {
    marginTop: '1rem',
  },
}))

export default useStyles
