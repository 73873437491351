import React, { useCallback, useState } from 'react'

import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Card, CardActions, CardContent, Button, Typography, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import AlertDialog from '../dialogs/ConfirmDialog'
import useRole from '../../useUserRole'

import useStyle from '../../styles/components/SetupTileStyle'
import statisticsApi from '../../api/statistics'
import useHandleError from '../../api/useHandleError'

const SetupTile = ({ id, index, title, tiles, setTiles }) => {
  const { userRole } = useRole()
  const [isDialogOpen, setIsOpenDialog] = useState(false)
  const classes = useStyle()
  const handleError = useHandleError()

  const history = useHistory()
  const handleClick = useCallback((e) => history.push(`/statistics/${e.currentTarget.name}/${id}`), [history])

  const handleDelete = () => {
    setTiles(tiles.filter((tile) => tile.id !== id))
    statisticsApi.deleteTile(id).catch(handleError)
    handleDialog()
  }

  const handleDialog = () => {
    setIsOpenDialog(!isDialogOpen)
  }

  return (
    <Card className={classes.root} aria-label={`tile-${index}`} elevation={4}>
      {userRole === 'admin' && (
        <IconButton className={classes.deleteButton} onClick={handleDialog} aria-label={`removeTileButton-${index}`}>
          <DeleteForeverIcon />
        </IconButton>
      )}
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActions}>
        {userRole === 'admin' && (
          <Button
            onClick={handleClick}
            name="edit"
            startIcon={<EditIcon />}
            className={classes.button}
            aria-label={`editTileButton-${index}`}
          >
            Edit
          </Button>
        )}

        <Button
          onClick={handleClick}
          name="view"
          startIcon={<VisibilityIcon />}
          className={classes.button}
          aria-label={`viewTileButton-${index}`}
        >
          View mode
        </Button>
      </CardActions>

      <AlertDialog
        isDialogOpen={isDialogOpen}
        title="Delete statistics tile"
        description={`Are you sure you want to delete tile "${title}"?`}
      >
        <Button onClick={handleDialog} autoFocus aria-label="cancelRemoveTileButton">
          Cancel
        </Button>
        <Button onClick={handleDelete} aria-label="confirmRemoveTileButton">
          Delete
        </Button>
      </AlertDialog>
    </Card>
  )
}

SetupTile.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  title: PropTypes.string,
  tiles: PropTypes.array,
  setTiles: PropTypes.func,
}

export default SetupTile
