import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { DataGrid } from '@material-ui/data-grid'

import useStyles from '../../styles/components/UsersTableStyle'
import UserAction from './UserTableAction'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

import { rowsPerRender as ROWS_PER_RENDER } from '../../config/config.json'

const getIcon = (name) => {
  switch (name) {
    case 'DeleteIcon':
      return <DeleteIcon aria-label="userDeleteButton" />
    case 'EditIcon':
      return <EditIcon aria-label="userEditButton" />
    case 'RotateLeftIcon':
      return <RotateLeftIcon aria-label="userChangePasswordButton" />
    default:
      return null
  }
}

const getPasswordField = (visiblePwdIndex, setVisiblePwdIndex) => {
  const PasswordField = (params) => {
    const isPasswordHidden = params.row.id !== visiblePwdIndex
    return (
      <div>
        <input
          type={isPasswordHidden ? 'password' : 'text'}
          value={params.row.password}
          style={{ border: 'none', background: 'inherit' }}
          disabled
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => (isPasswordHidden ? setVisiblePwdIndex(params.row.id) : setVisiblePwdIndex(null))}
          edge="end"
          tabIndex="-1"
        >
          {isPasswordHidden ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </div>
    )
  }
  return PasswordField
}

const getUserActions = (parentStyles, actions, actionCalls) => {
  const UserActions = (params) => {
    return (
      <div className={parentStyles.actionButtons}>
        {actions.map((action) => (
          <UserAction
            disabled={actionCalls[action.name].disabledIndex === params.row.id}
            key={action.name}
            label={action.label}
            action={() => actionCalls[action.name].callback(params.row.id)}
          >
            {getIcon(action.icon)}
          </UserAction>
        ))}
      </div>
    )
  }
  return UserActions
}

const UsersTable = ({ rows, columns, loading = false, actionCalls, tableSize, setCurrentPage }) => {
  const [visiblePwdIndex, setVisiblePwdIndex] = useState(null)

  const classes = useStyles()

  const parsedColumns = columns.map((col) => {
    const column = {
      ...col,
      headerClassName: classes.tableHeader,
    }
    if (col.isPasswordField) {
      column.renderCell = getPasswordField(visiblePwdIndex, setVisiblePwdIndex)
    }
    if (col.showActions) {
      column.renderCell = getUserActions(classes, col.showActions, actionCalls)
    }
    return column
  })

  const handlePageChange = (e) => {
    setCurrentPage(e + 1)
  }

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows}
        columns={parsedColumns}
        rowsPerPageOptions={[ROWS_PER_RENDER]}
        pageSize={ROWS_PER_RENDER}
        rowCount={tableSize}
        loading={loading}
        onPageChange={handlePageChange}
        disableSelectionOnClick
        aria-label="usersTable"
      />
    </div>
  )
}

UsersTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  actionCalls: PropTypes.object,
  tableSize: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default UsersTable
