import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: '0 2.5rem',
    borderTop: `2px solid ${theme.palette.primary.main}`,
    height: '5rem',
    width: 'calc(100vw - 5.5rem)',
    padding: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '0 2rem',
      height: 'auto',
    },
  },
  text: {
    '& a': {
      marginLeft: '0.6rem',
      textDecoration: 'none',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  logo: {
    position: 'absolute',
    right: '0',
    height: ' 55%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: 'clamp(6rem, 60%, 12rem)',
      height: 'unset',
      marginTop: '1rem',
    },
  },
}))

export default useStyles
