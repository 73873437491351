import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(58,93,174)',
      light: 'rgb(114,147,203)',
      dark: 'rgb(3,61,131)',
    },
    secondary: {
      main: 'rgb(115,34,129)',
      light: 'rgb(150,89,161)',
      dark: 'rgb(91,33,93)',
    },
    error: {
      main: 'rgb(232,78,27)',
      light: 'rgb(239,126,82)',
      dark: 'rgb(189,22,34)',
    },
    success: {
      main: 'rgb(79,174,71)',
      light: 'rgb(174,201,11)',
      dark: 'rgb(54,130,61)',
      contrastText: 'white',
    },
  },
  typography: {
    fontFamily: `"Roboto", "Arial", "Avenir Next", sans-serif`,
  },
})

export default theme
