import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  card: {
    position: 'relative',
    overflow: 'auto',
    margin: '5%',
    maxHeight: '90vh',
    maxWidth: '56rem',
    padding: '1rem',
  },
  cardContent: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  closeIcon: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    transition: '0.1s all',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  item: {
    flexGrow: '1',
    flexDirection: 'column',
    display: 'flex',
    minWidth: '48%',
    margin: '0.5rem 1%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '98%',
    },
  },
  itemType: {
    lineHeight: '1',
  },
  mainItem: {
    marginBottom: '1rem',
    minWidth: '98%',
  },
  mainItemType: {
    marginRight: '1rem',
  },
  textAreaItem: {
    width: '100%',
    '&:first-of-type': {
      marginTop: '0.5rem',
    },
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  buttonGroup: {
    justifyContent: 'space-around',
    display: 'flex',
  },
}))

export default useStyles
