import React, { useState, useEffect, createContext } from 'react'

import { useParams } from 'react-router-dom'

import { TextField } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PreviewIcon from '@material-ui/icons/Visibility'

import StatisticsEditTable from '../components/tables/StatisticsEditTable'

import useStyles from '../styles/pages/StatisticsEditPageStyle'
import LinkButton from '../components/LinkButton'
import statisticsApi from '../api/statistics'
import useHandleError from '../api/useHandleError'

export const StatisticEditPageContext = createContext({})

const StatisticsEditPage = () => {
  const { id } = useParams()
  const [tileTitle, setTileTitle] = useState('')
  const [rowsData, setRowsData] = useState([])
  const classes = useStyles()
  const handleError = useHandleError()

  const getData = () => {
    const apiRequest = id ? statisticsApi.getTile(id) : statisticsApi.list()
    return apiRequest.then((data) => data.data).catch(handleError)
  }

  const handleUpdate = () => {
    const updatedTile = {
      name: tileTitle,
      rows: rowsData,
    }
    statisticsApi.updateTile(id, updatedTile).catch(handleError)
  }

  const handleChange = (e) => {
    setTileTitle(e.target.value)
  }

  useEffect(async () => {
    const getTileData = await getData()
    if (getTileData) {
      setRowsData(getTileData.rows)
      setTileTitle(id ? getTileData.name : '')
    }
  }, [])

  return (
    <StatisticEditPageContext.Provider value={{ getData: getData }}>
      <main className={classes.root}>
        <LinkButton
          handleClick={handleUpdate}
          url={'/statistics/'}
          icon={<ArrowBackIcon />}
          aria-label="goBackButton"
        />
        <LinkButton
          handleClick={handleUpdate}
          url={`/statistics/view/${id}`}
          icon={<PreviewIcon />}
          text="Preview"
          variant="outlined"
        />
        <TextField
          required
          label="Statistic view name"
          variant="outlined"
          value={tileTitle || ''}
          className={classes.tileName}
          onChange={handleChange}
          onBlur={handleUpdate}
          aria-label="tileNameInput"
        />
        <StatisticsEditTable rowsData={rowsData} />
      </main>
    </StatisticEditPageContext.Provider>
  )
}

export default StatisticsEditPage
