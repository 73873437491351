import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  gordonValue: [],
  roles: [],
  selectedUser: {},
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.value = action.payload
    },
    addUser: (state, action) => {
      state.value.push(action.payload)
    },
    setUserRoles: (state, action) => {
      state.roles = action.payload
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },

    setGordonUsers: (state, action) => {
      state.gordonValue = action.payload
    },
    setGordonRoles: (state, action) => {
      state.gordonRoles = action.payload
    },
    addGordonUser: (state, action) => {
      state.gordonValue.push(action.payload)
    },
    wipeAllUsersData: (state) => {
      state.value = []
      state.gordonValue = []
      state.selectedUser = []
    },
  },
})

export const { setUsers, addUser, setUserRoles, setSelectedUser, setGordonUsers, setGordonRoles, addGordonUser, wipeAllUsersData } =
  usersSlice.actions

export default usersSlice.reducer
