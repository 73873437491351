import React, { useState, useRef, useCallback, useContext, useMemo, useEffect, createContext } from 'react'

import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { setViewportWidth } from '../../useViewPort'
import { RowsContext } from '../../pages/TablePage'
import TableGridRow from './rows/TableGridRow'
import { tableColumns } from '../../config/dataTableColumns'
import { rowsPerRender as ROWS_PER_RENDER } from '../../config/config.json'

import useStyle from '../../styles/components/DataGridTableStyle'

export const CheckAllRows = createContext()

const DataGridTable = ({ handleModal, setClickedSequenceId, setClickedRowIndex, updatedItemIndex }) => {
  const [rowsToRender, setRowsToRender] = useState({})
  const [rowsCountToRender, setRowsCountToRender] = useState(ROWS_PER_RENDER)
  const { setSelectedRows, rows, isResultEmpty, currentPage, setCurrentPage, maxRowsCount, setIsLoading, isLoading } =
    useContext(RowsContext)
  const theme = useTheme()
  const classes = useStyle()

  /* Check if scrollbar is active, after new data fetch and recalculate viewport width */
  !isLoading && setTimeout(() => setViewportWidth())

  const handleRowSelection = (e) => {
    const wasCheckboxClicked = e.target.matches('input[type="checkbox"]')

    let { name, checked } = wasCheckboxClicked
      ? e.target
      : e.target.parentElement.querySelector('input[type="checkbox"]')

    /* 
    If was row selected without clicking on checkbox, checked state has to be 
    reversed because row selection and checkbox was updated in same 
    render iteration therefore new state is not reflected yet 
    */
    if (!wasCheckboxClicked) checked = !checked

    const rowId = parseInt(name)

    checked
      ? setSelectedRows((prevState) => new Set(prevState).add(rowId))
      : setSelectedRows((prevState) => {
          const nextState = new Set(prevState)

          nextState.delete(rowId)

          return nextState
        })
  }

  /* If last row is displayed visible, renders next ROWS_PER_RENDER rows */
  const observer = useRef()
  const lastRowElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        const nextRenderCount = rowsCountToRender + ROWS_PER_RENDER

        if (nextRenderCount >= maxRowsCount) {
          setRowsCountToRender(maxRowsCount)
        } else {
          setCurrentPage(currentPage + 1)
          setRowsCountToRender(nextRenderCount)
          setIsLoading(rowsCountToRender === currentPage * ROWS_PER_RENDER)
        }
      }
    })

    if (node) observer.current.observe(node)
  })

  const columns = tableColumns.map((column, index) => {
    return (
      <TableCell key={index} className={classes.tableHeaderCell} width="10rem">
        {column.headerName}
      </TableCell>
    )
  })

  const handleCellClick = (e) => {
    const sequenceClicked = e.currentTarget.dataset.sequence
    setClickedSequenceId(sequenceClicked)

    const rowIndexClicked = parseInt(e.currentTarget.dataset.index)
    setClickedRowIndex(rowIndexClicked)
    handleModal()
  }

  const alreadyRenderedCountRef = useRef()
  useEffect(() => {
    alreadyRenderedCountRef.current = rowsCountToRender
  })

  useEffect(() => {
    currentPage === 2 && setRowsCountToRender(ROWS_PER_RENDER)
  }, [currentPage])

  /* Renders only next iteration of rows */
  const renderRows = (toRender) => {
    if (rows.length === 0) {
      return []
    }

    const { current } = alreadyRenderedCountRef

    let alreadyRenderedCount = 0

    alreadyRenderedCount = alreadyRenderedCount === undefined ? 0 : current

    if (current === toRender) {
      alreadyRenderedCount = 0
    }

    let newRows = alreadyRenderedCount === 0 ? {} : rowsCountToRender

    for (let index = alreadyRenderedCount; index < toRender && index < rows.length; index++) {
      newRows = {
        ...newRows,
        [index]: (
          <TableGridRow
            key={index}
            index={index}
            lastRowElementReft={toRender === index + 1 ? lastRowElementRef : null}
            handleRowSelection={handleRowSelection}
            handleCellClick={handleCellClick}
            row={rows[index]}
          />
        ),
      }
    }

    const whichRowsToRender = alreadyRenderedCount === 0 ? newRows : { ...rowsToRender, ...newRows }
    setRowsToRender(whichRowsToRender)
  }

  useMemo(() => renderRows(rowsCountToRender), [JSON.stringify(rows), rowsCountToRender])

  /* Rerenders only affected rows */
  useEffect(() => {
    if (updatedItemIndex.length !== 0) {
      let renderUpdatedItems = { ...rowsToRender }

      updatedItemIndex.forEach((rowIndex) => {
        if (!rowsToRender[rowIndex]) return

        const lastRowELement = rowsToRender[rowIndex].props.lastRowElementReft

        renderUpdatedItems = {
          ...renderUpdatedItems,
          [rowIndex]: (
            <TableGridRow
              key={rowIndex}
              index={rowIndex}
              lastRowElementReft={lastRowELement}
              handleRowSelection={handleRowSelection}
              handleCellClick={handleCellClick}
              row={rows[rowIndex]}
            />
          ),
        }
      })

      setRowsToRender(renderUpdatedItems)
    }
  }, [updatedItemIndex])

  return (
    <TableContainer component={Paper} elevation={0} className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell></TableCell>

            {columns}
          </TableRow>
        </TableHead>
        <TableBody>{!isLoading && !isResultEmpty && Object.values(rowsToRender)}</TableBody>
      </Table>
      {(isLoading || isResultEmpty) && (
        <div className={classes.tableLoader}>
          {isLoading ? (
            <Loader type="TailSpin" color={theme.palette.primary.light} className={classes.loading} />
          ) : (
            <h3>No data found</h3>
          )}
        </div>
      )}
    </TableContainer>
  )
}

DataGridTable.propTypes = {
  handleModal: PropTypes.func,
  setClickedSequenceId: PropTypes.func,
  setClickedRowIndex: PropTypes.func,
  updatedItemIndex: PropTypes.array,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  maxRowsCount: PropTypes.number,
}

export default DataGridTable
