import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },
})

export default useStyles
