import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    boxSizing: 'border-box',
    margin: '1rem 0',
    height: '5rem',
    padding: '0.5rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  exportPicker: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: '12rem',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      left: 'unset',
      transform: 'unset',
    },
  },
  buttonGroup: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  item: {
    margin: '0 1rem',
    height: '100%',
    width: '16rem',
    [theme.breakpoints.down('lg')]: {
      margin: '0 0.6rem',
      width: '12rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto  ',
      margin: '0.6rem 1rem',
    },
  },
}))

export default useStyles
