import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      padding: '1.2rem',
    },
  },
  title: {
    marginBottom: '1.2rem',
    padding: '0',
  },
  content: {
    marginBottom: '1.8rem',
    padding: '0',
  },
  actions: {
    padding: '0',
  },
})

export default useStyles
