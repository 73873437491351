import React, { useState, useContext, useEffect } from 'react'

import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Paper, Tabs, Tab as LinkTab } from '@material-ui/core'

import { setViewportWidth } from '../useViewPort'
import { removeToken } from '../useToken'
import useRole from '../useUserRole'
import { AppSettingsContext } from '../App'

import useStyles from '../styles/components/NavigationStyle'

import logo from '../images/logo_white.png'
import { removeSessionFilters } from '../useSessionFilters'

const Navigation = (props) => {
  // props are passed from react router via withRouter()
  const [activeTab, setActiveTab] = useState(0)
  const [activeUserRole, setActiveUserRole] = useState('')
  const { userRole, removeUserRole } = useRole()
  const { isLoggedIn, setIsLoggedIn } = useContext(AppSettingsContext)

  const classes = useStyles()

  const handleTabClick = (event, clickedTab) => {
    setActiveTab(clickedTab ? clickedTab : 0)
    /* Check if scrollbar is active on new page and recalculate viewport width */
    setTimeout(() => setViewportWidth())
  }

  const handleLogOut = () => {
    removeToken()
    removeUserRole()
    removeSessionFilters()
    setIsLoggedIn(false)
  }

  useEffect(() => {
    const loadedUserRole = userRole ? userRole : sessionStorage.getItem('userRole')

    setActiveUserRole(loadedUserRole)

    switch (props?.location.pathname) {
      case '/statistics':
        setActiveTab(0)
        break
      case '/table':
        setActiveTab(1)
        break
      case '/users':
        setActiveTab(2)
        break
      default:
        loadedUserRole === 'admin' ? setActiveTab(1) : setActiveTab(0)
    }
  }, [userRole, isLoggedIn])

  useEffect(() => {
    setActiveTab(0)
  }, [])

  return (
    <Paper className={`${classes.root} sticky-vertical`}>
      <Link to={isLoggedIn ? '/statistics' : '/login'} className={classes.logoLink} onClick={handleTabClick}>
        <img src={logo} className={classes.logo} alt="logo" />
      </Link>
      {isLoggedIn && (
        <Tabs
          value={activeTab}
          onChange={handleTabClick}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="navigation"
          className={classes.nav}
        >
          {(activeUserRole === 'admin' || activeUserRole === 'viewer' || activeUserRole === 'customer') && (
            <LinkTab label="Statistics" component={Link} to="/statistics" aria-label="navButtonStatistics" />
          )}
          {(activeUserRole === 'admin' || activeUserRole === 'customer') && (
            <LinkTab label="Table" component={Link} to="/table" aria-label="navButtonTable" />
          )}
          {activeUserRole === 'admin' && (
            <LinkTab label="Users" component={Link} to="/users" aria-label="navButtonUsers" />
          )}
          <LinkTab label="Logout" onClick={handleLogOut} className={classes.logOutButton} aria-label="logoutButton" />
        </Tabs>
      )}
    </Paper>
  )
}

Navigation.propTypes = {
  location: PropTypes.object,
}

export default withRouter(Navigation)
