import client from './axios'

const statisticsApi = {
  list: (page) => {
    const config = { params: { page } }
    return client.get('statistics/', page != null ? config : undefined)
  },

  createTile: (name) => {
    return client.post('statistics/', { name })
  },

  getRow: (id) => {
    return client.get(`statistics/rows/${id}/`)
  },

  updateRow: (id, row) => {
    return client.put(`statistics/rows/${id}/`, row)
  },

  deleteRow: (id) => {
    return client.delete(`statistics/rows/${id}/`)
  },

  getTile: (id) => {
    return client.get(`statistics/${id}/`)
  },

  updateTile: (id, data) => {
    return client.put(`statistics/${id}/`, data)
  },

  deleteTile: (id) => {
    return client.delete(`statistics/${id}/`)
  },

  addRowsToTile: (id, row) => {
    return client.post(`statistics/${id}/rows/`, row)
  },

  reorderTiles: (tilesList) => {
    return client.put(`statistics/order/`, tilesList)
  },

  reorderRows: (rowsList, tileId) => {
    return client.put(`statistics/rows/order/${tileId}/`, rowsList)
  },

  listNames: (page) => {
    const config = { params: { page } }
    return client.get('statistics/names', page != null ? config : undefined)
  },
}

export default statisticsApi
