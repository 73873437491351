import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import AlertDialog from '../dialogs/ConfirmDialog'
import UserDialog from '../dialogs/UserDialog'
import UsersTable from '../tables/UsersTable'

import useStyles from '../../styles/pages/UsersPageStyle'
import columns from '../../config/dataTableColumns.json'
import usersApi from '../../api/users'
import gordonUsersApi from '../../api/gordonUsers'
import { setUsers, setSelectedUser, setGordonUsers, wipeAllUsersData } from '../../features/usersSlice'
import { setSystemMessage } from '../../features/systemMessageSlice'

const UsersManagementPanel = ({ isGordonUser = false }) => {
  const [userAction, setUserAction] = useState('addUser')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [tableSize, setTableSize] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [loadedPages, setLoadedPages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [lastAdminIndex, setLastAdminIndex] = useState(null)

  const isRemoveUserAction = userAction === 'removeUser'

  const dataTypeApi = isGordonUser ? gordonUsersApi : usersApi

  const dispatch = useDispatch()
  const users = isGordonUser
    ? useSelector((state) => state.users.gordonValue)
    : useSelector((state) => state.users.value)

  const selectedUser = useSelector((state) => state.users.selectedUser)

  const classes = useStyles()

  useEffect(() => {
    fetchData()
  }, [currentPage])

  useEffect(() => {
    checkLastAdmin()
  }, [users])

  useEffect(() => {
    return () => {
      /* Reset state on unmount to initial value */
      dispatch(wipeAllUsersData())
    }
  }, [])

  const updateUsersData = (updatedData) => {
    isGordonUser ? dispatch(setGordonUsers(updatedData)) : dispatch(setUsers(updatedData))
  }

  const checkLastAdmin = () => {
    let adminIndex = null
    const admins = users.filter((user, i) => {
      if (user.role != null && (user.role.id === 1 || user.role.name === 'admin')) {
        adminIndex = i
        return true
      }
      return false
    })
    setLastAdminIndex(admins.length === 1 ? adminIndex : null)
  }

  const fetchData = async () => {
    setIsLoading(true)

    await dataTypeApi
      .list(currentPage)
      .then((res) => {
        if (!loadedPages.includes(currentPage)) {
          setLoadedPages([...loadedPages, currentPage])
          updateUsersData([...users, ...res.data.results])
        }

        setTableSize(res.data.count)
      })
      .catch((error) => {
        useDispatch(
          setSystemMessage({
            message: `Users data fetching has failed with error: ${error.message}`,
            severity: 'error',
          })
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleDeleteUser = async () => {
    await dataTypeApi
      .delete(selectedUser.id)
      .then(() => {
        updateUsersData(users.filter((user) => user.username !== selectedUser.username)) // remove user from users list

        dispatch(
          setSystemMessage({
            message: `${isGordonUser ? 'Gordon user' : 'user'} was successfully deleted`,
            severity: 'success',
          })
        )
      })
      .catch((error) => {
        if (error.request) {
          dispatch(
            setSystemMessage({
              message: 'Server is not responding, please try again later',
              severity: 'error',
            })
          )
        } else {
          dispatch(setSystemMessage({ message: error.message, severity: 'error' }))
        }
      })

    setIsDialogOpen(false)
  }

  const showDialog = (action, userIndex = null) => {
    if (userIndex !== null) {
      dispatch(setSelectedUser(users[userIndex]))
    }

    setUserAction(action)
    setIsDialogOpen(true)
  }

  const handleExport = async () => {
    await gordonUsersApi
      .export()
      .then((res) => {
        const blob = new Blob([res.data], {
          type: 'data:text/csvcharset=utf-8,',
        })
        const blobURL = window.URL.createObjectURL(blob)
        let anchor = document.createElement('a')
        anchor.download = `export_gordon_${new Date().toJSON().slice(0, 10)}.csv`
        anchor.href = blobURL
        anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':')
        anchor.click()
      })
      .catch((error) => {
        setSystemMessage({
          text: `Export failed with error ${error.message}`,
          type: 'error',
        })
      })
  }

  return (
    <div className={classes.panel}>
      <h2>{isGordonUser ? 'Gordon Users' : 'User Management'}</h2>
      <UsersTable
        rows={users.map((user, index) => ({
          username: user.username,
          role: user.role != null ? user.role.name : '',
          id: index,
          actions: null,
        }))}
        columns={columns.usersTable}
        loading={isLoading}
        tableSize={tableSize}
        setCurrentPage={setCurrentPage}
        actionCalls={{
          delete: {
            callback: (index) => showDialog('removeUser', index),
            disabledIndex: lastAdminIndex,
          },
          changePassword: {
            callback: (index) => showDialog('passwordChange', index),
          },
        }}
      />

      <div>
        <Button className={classes.button} onClick={() => showDialog('addUser')} aria-label="addUserButton">
          Add
        </Button>
        {isGordonUser && (
          <Button className={classes.button} onClick={handleExport}>
            Export to CSV
          </Button>
        )}
      </div>

      {!isRemoveUserAction ? (
        <UserDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          action={userAction}
          isGordonUser={isGordonUser}
        />
      ) : (
        <AlertDialog
          isDialogOpen={isDialogOpen}
          title="Delete user"
          description={`Are you sure you want to delete user "${selectedUser?.username}"`}
        >
          <Button autoFocus onClick={() => setIsDialogOpen(false)} aria-label="cancelUserDeleteButton">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="primary" variant="contained" aria-label="confirmUserDeleteButton">
            Delete
          </Button>
        </AlertDialog>
      )}
    </div>
  )
}

UsersManagementPanel.propTypes = {
  isGordonUser: PropTypes.bool,
}

export default UsersManagementPanel
