import React, { useState, useEffect } from 'react'

import { Typography } from '@material-ui/core'

import SetupTile from '../components/tiles/SetupTile'
import AddNewTile from '../components/tiles/AddNewTile'
import useRole from '../useUserRole'

import useStyles from '../styles/pages/StatisticsPageStyle'
import statisticsApi from '../api/statistics'
import useHandleError from '../api/useHandleError'

const StatisticsPage = () => {
  const { userRole } = useRole()
  const [tiles, setTiles] = useState([])
  const classes = useStyles()
  const handleError = useHandleError()

  const getTiles = () => {
    return statisticsApi
      .listNames()
      .then((data) => data.data.results)
      .catch(handleError)
  }

  useEffect(async () => {
    setTiles(await getTiles())
  }, [])

  const renderTiles = (tilesList) =>
    tilesList &&
    tilesList.length !== 0 &&
    tilesList.map((tile, index) => (
      <SetupTile key={tile.id} index={index} id={tile.id} title={tile.name} tiles={tilesList} setTiles={setTiles} />
    ))

  return (
    <main className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Select statistics configuration to display
      </Typography>
      <section className={classes.tilesGrid} aria-label="tilesGrid">
        {renderTiles(tiles)}
        {userRole === 'admin' && <AddNewTile tiles={tiles} setTiles={setTiles} />}
      </section>
    </main>
  )
}

export default StatisticsPage
