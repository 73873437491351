import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import StatisticRow from './rows/StatisticRow'
import statisticsApi from '../../api/statistics'
import useHandleError from '../../api/useHandleError'

import useStyle from '../../styles/components/StatisticsEditTableStyle'

const StatisticsEditTable = ({ rowsData }) => {
  const { id } = useParams()
  const [tableRows, setTableRows] = useState(rowsData)
  const [isDragDisabled, setIsDragDisabled] = useState(false)
  const handleError = useHandleError()
  const classes = useStyle()

  /* Add new empty row */
  const handleAdd = () => {
    setTableRows([
      ...tableRows,
      {
        id: `tmp-id-${Date.now()}`,
        name: '',
        count: null,
        filters: {},
        isNew: true,
        order: tableRows.length,
      },
    ])
  }

  useEffect(() => {
    setTableRows(rowsData)
  }, [rowsData])

  const renderTableBody =
    tableRows &&
    tableRows.length !== 0 &&
    tableRows.map((row, index) => {
      return (
        <StatisticRow
          key={`${index}-${row.id}`}
          index={index}
          row={row}
          tableRows={tableRows}
          setTableRows={setTableRows}
          isDragDisabled={isDragDisabled}
          setIsDragDisabled={setIsDragDisabled}
        />
      )
    })

  const asignCorrectOrder = (rowsList) => {
    return rowsList.map((row, index) => {
      row.order = index
      return row
    })
  }

  const saveNewOrder = (rowsList) => {
    statisticsApi.reorderRows(rowsList, id).catch(handleError)
  }

  const onDragEnd = (result) => {
    const { destination, source } = result
    if (!destination) return

    if (destination.draggableId === source.draggableId && destination.index === source.index) return

    const reorderedTiles = Array.from(tableRows)

    reorderedTiles.splice(destination.index, 0, reorderedTiles.splice(source.index, 1)[0])

    const correctOrder = asignCorrectOrder(reorderedTiles)

    setTableRows(correctOrder)
    saveNewOrder(correctOrder)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <caption className={classes.addNewButton}>
            <IconButton onClick={handleAdd} aria-label="addFilterButton">
              <AddCircleOutlineIcon />
            </IconButton>
          </caption>

          <TableHead>
            <TableRow>
              <TableCell className={classes.column} align="center">
                Name
              </TableCell>
              <TableCell className={classes.column} align="center">
                Filtered value
              </TableCell>
              <TableCell className={classes.column} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <Droppable droppableId="2">
            {(provided) => (
              <TableBody aria-label="filtersTable" ref={provided.innerRef} {...provided.droppableProps}>
                {renderTableBody}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </TableContainer>
    </DragDropContext>
  )
}

StatisticsEditTable.propTypes = {
  rowsData: PropTypes.array,
}

export default StatisticsEditTable
