import { configureStore } from '@reduxjs/toolkit'

import usersReducer from '../features/usersSlice'
import systemMessageReducer from '../features/systemMessageSlice'

export const store = configureStore({
  reducer: {
    users: usersReducer,
    systemMessage: systemMessageReducer,
  },
})
