import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflowX: 'initial',
    width: '100%',
    minHeight: '26rem',
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
  },
  table: {
    minWidth: '35rem',
  },
  tableHead: {
    position: 'sticky',
    top: '0',
    zIndex: '2',
    background: theme.palette.primary.light,
  },
  tableHeaderCell: {
    position: 'relative',
    whiteSpace: 'noWrap',
    textAlign: 'center',
    color: 'white',
    '&:nth-last-of-type(-n+2)': {
      padding: '0 8rem',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '100%',
      transform: 'translateY(-50%)',
      height: '1.5rem',
      width: '2px',
      background: 'rgba(255,255,255,0.3)',
    },
  },
  tableLoader: {
    position: 'sticky',
    top: '13rem',
    right: '0',
    left: '0',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '49rem',
    maxWidth: 'var(--viewportWidth)',
    '& svg': {
      height: '3rem',
      width: '3rem',
    },
  },
}))

export default useStyles
