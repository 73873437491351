import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: '20rem',
  },
  cardContent: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    height: '80%',
  },
  cardActions: {
    height: '20%',
    padding: '0',
  },
  button: {
    flexGrow: '1',
    marginLeft: 'unset !important',
    borderTop: '1px solid rgba(0,0,0,0.05)',
    borderRadius: '0',
    width: '50%',
    height: '100%',
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:first-of-type': {
      borderRight: '1px solid rgba(255,255,255,0.2)',
    },
  },
  deleteButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
}))

export default useStyles
