import React from 'react'

import PropTypes from 'prop-types'

import { IconButton, Tooltip } from '@material-ui/core'

const UserAction = ({ label, action, children, disabled = false }) => {
  return (
    <Tooltip title={label}>
      <div>
        <IconButton aria-label={label} onClick={action} disabled={disabled}>
          {children}
        </IconButton>
      </div>
    </Tooltip>
  )
}

UserAction.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

export default UserAction
