import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { DateTimePicker } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons//Event'

const DateTimePickerFilterInput = ({
  filter,
  classes,
  selectedValues,
  handleSelectedValues,
  // eslint-disable-next-line no-unused-vars
  isSetToMidnight = false,
  filterWidthClass,
}) => {
  const [tempValue, setTempValue] = useState(null)

  const evalValue = selectedValues[filter.field] ? selectedValues[filter.field] : null

  const getFormatedDateTime = (date) => {
    return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('cs-CZ')}`
  }

  return (
    <DateTimePicker
      inputVariant="outlined"
      label={filter.headerName}
      name={filter.field}
      ampm={false}
      className={filterWidthClass}
      value={evalValue ? evalValue : tempValue}
      onFocus={() => {
        const date = new Date()

        isSetToMidnight ? date.setHours(23, 59, 59, 59) : date.setHours(0, 0, 0, 0)
        setTempValue(getFormatedDateTime(date))
      }}
      onBlur={() => {
        setTempValue(null)
      }}
      onChange={(event) => {
        let preformattedDate = getFormatedDateTime(event)

        handleSelectedValues(event, { [filter.field]: preformattedDate })
      }}
      InputProps={{
        endAdornment: !selectedValues[filter.field] ? (
          <IconButton className={classes.dateIcon}>
            <EventIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              handleSelectedValues(event, { [filter.field]: null }, 'clear')
            }}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  )
}

DateTimePickerFilterInput.propTypes = {
  filter: PropTypes.object,
  classes: PropTypes.object,
  selectedValues: PropTypes.object,
  handleSelectedValues: PropTypes.func,
  isSetToMidnight: PropTypes.bool,
  filterWidthClass: PropTypes.string,
}

export default DateTimePickerFilterInput
