import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-between',
    display: 'flex',
    borderRadius: '0',
    height: '5rem',
    background: theme.palette.primary.dark,
  },
  logoLink: {
    alignSelf: 'center',
  },
  logo: {
    width: '100%',
    maxWidth: '18rem',
    height: 'auto',
  },
  appName: {
    marginRight: 'auto',
    padding: '0 1rem',
    fontSize: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  nav: {
    '& a, & .Mui-selected': {
      color: 'white',
    },
    '& .Mui-selected': {
      background: theme.palette.primary.main,
    },
    '& .MuiTabs-scroller': {
      display: 'flex',
    },
  },
  logOutButton: {
    background: theme.palette.error.dark,
    color: 'white',
  },
}))

export default useStyles
