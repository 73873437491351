import React, { useState, useContext } from 'react'

import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Modal, Card, CardContent, Typography, TextField, Button } from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'

import useRole from '../../useUserRole'
import { RowsContext } from '../../pages/TablePage'
import { SelectFilterInput } from '../filters/inputs'
import { tableColumns } from '../../config/dataTableColumns.json'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import reportApi from '../../api/table'
import { setSystemMessage } from '../../features/systemMessageSlice'

import useStyles from '../../styles/components/RowDetailModalStyle'

const saveTemplateAsFile = (filename, dataObjToWrite) => {
  const blob = new Blob([JSON.stringify(dataObjToWrite, null, 2)], {
    type: 'text/json',
  })
  const link = document.createElement('a')

  link.download = filename
  link.href = window.URL.createObjectURL(blob)
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':')

  const e = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })

  link.dispatchEvent(e)
  link.remove()
}

const RowDetailModal = ({ clickedRowIndex, isModalOpen, handleModal, sequenceId, setUpdatedItemIndex }) => {
  const { selectedRows, setRows, rows } = useContext(RowsContext)
  const { userRole } = useRole()
  const isAdmin = userRole === 'admin'

  const targetRow = sequenceId ? rows.find((item) => item.sequence_id === sequenceId) : rows[clickedRowIndex]

  const [inputData, setInputData] = useState(targetRow)
  const [edited, setEdited] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isSelectedAndSequence = sequenceId && selectedRows.size > 0
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const handleError = (error) => {
    if (error.response) {
      dispatch(setSystemMessage({ message: 'Invalid input data', severity: 'error' }))
    } else if (error.request) {
      dispatch(setSystemMessage({ message: 'Server is not responding, please try again later', severity: 'error' }))
    } else {
      dispatch(setSystemMessage({ message: error.message, severity: 'error' }))
    }
  }

  const sequenceDataRename = (data, reverse = false) => {
    const copyUpdatedData = { ...data }

    if (reverse) {
      copyUpdatedData.sequence_description = copyUpdatedData.description
      copyUpdatedData.sequence_name = copyUpdatedData.name
      copyUpdatedData.sequence_invoice = copyUpdatedData.project
      copyUpdatedData.login = copyUpdatedData.require_login

      delete copyUpdatedData.description
      delete copyUpdatedData.name
      delete copyUpdatedData.project
      delete copyUpdatedData.require_login
    } else {
      copyUpdatedData.description = copyUpdatedData.sequence_description
      copyUpdatedData.name = copyUpdatedData.sequence_name
      copyUpdatedData.project = copyUpdatedData.sequence_invoice
      copyUpdatedData.user = copyUpdatedData.user?.username
    }

    return copyUpdatedData
  }

  /* Determine if should be updated report or sequence and PUT formated values */
  const updateRow = async (updatedRowData, isSequence = false) => {
    const rowId = rows[clickedRowIndex]?.id

    const copyUpdatedData = sequenceDataRename(updatedRowData)

    if (isSequence) {
      copyUpdatedData.sequence = copyUpdatedData.sequence_id
      reportApi.updateReport(updatedRowData.id, copyUpdatedData)
    }

    const request = isSequence
      ? reportApi.updateSequence(copyUpdatedData.sequence_id, copyUpdatedData)
      : reportApi.updateReport(rowId, copyUpdatedData)
    return await request.then((data) => data.data).catch((error) => handleError(error))
  }

  /* Create new sequence with newly generated ID */
  const createSequence = async () => {
    const newSequenceData = sequenceDataRename(inputData)

    delete newSequenceData.sequence_id

    return await reportApi
      .createSequence([newSequenceData])
      .then((data) => data.data)
      .catch((error) => handleError(error))
  }

  /* Assign sequence to target rows */
  const assignSequence = (newSequence) => {
    let newRows = [...rows]
    const rowIndexes = []
    /* Firstly updated data on backed, then updates fetched rows and at last return affected rows  */
    selectedRows.forEach(async (rowId) => {
      const rowIndex = rows.indexOf(rows.find((row) => row.id === rowId))
      let newSequenceData = { ...rows[rowIndex], ...newSequence[0] }
      newSequenceData = sequenceDataRename(newSequenceData, true)
      updateRow(newSequenceData, true)
      newRows = updateRowArray(rowIndex, newSequenceData, undefined, newRows)
      rowIndexes.push(rowIndex)
    })

    setRows(newRows)
    return rowIndexes
  }

  /* Returns indexes of rows to rerender */
  const getAffectedRows = (selectedRow) => {
    if (isSelectedAndSequence) {
      return selectedRows
    }

    if (!sequenceId) {
      return [clickedRowIndex]
    }

    return rows.flatMap((row, index) => (row.sequence_id === selectedRow.sequence_id ? index : []))
  }

  /* Find and update column value in array of rows  */
  const updateRowArray = (rowIndex, newData, affectedRows, rowsData = rows) => {
    const updatedRowData = [...rowsData]

    if (typeof rowIndex === 'number') {
      updatedRowData[rowIndex] = newData
    } else {
      affectedRows.forEach((rowIndex) => {
        updatedRowData[rowIndex] = newData
      })
    }

    return updatedRowData
  }

  const handleSequenceDownload = async () => {
    const sequenceData = await reportApi.downloadSequence(sequenceId).then((data) => data.data)
    saveTemplateAsFile(`export_sequence_${sequenceId}.json`, sequenceData)
  }

  /* Function to update clicked or selected rows with edit data */
  const handleEditSelected = async () => {
    handleModal()
    handleDialog()

    /* Selected multiple rows with checkbox */
    if (isSelectedAndSequence) {
      const createdSequence = await createSequence()
      const affectedRowsIndexes = assignSequence(createdSequence)
      setUpdatedItemIndex(affectedRowsIndexes)
      /* Clicked column */
    } else {
      updateRow(inputData, sequenceId && true)
      const affectedRows = getAffectedRows(rows[clickedRowIndex])
      const updatedRowData = updateRowArray(undefined, inputData, affectedRows)
      setRows(updatedRowData)
      setUpdatedItemIndex(affectedRows)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    const newData = {
      ...inputData,
      [name]: value,
    }

    setInputData(newData)

    setEdited(JSON.stringify(rows[clickedRowIndex] !== JSON.stringify(newData)))
  }

  const filterDataCondition = (column) => (sequenceId ? column.isSequenceAttribute : column.isInDetail)

  const modalClickedEditableRender = []
  const modalSequenceDisplayOnlyClickedRender = tableColumns
    .filter((column) => filterDataCondition(column))
    .map((column, index) => {
      const fieldValue = inputData[column.field]

      if (column.isEditable && isAdmin) {
        if (column.options.length !== 0) {
          modalClickedEditableRender.push(
            <SelectFilterInput
              key={index}
              stylingClass={classes.item}
              filter={column}
              selectedValues={inputData}
              handleSelectedValues={handleChange}
              options={column.options}
              hasNullValue={false}
            />
          )
          return []
        }

        modalClickedEditableRender.push(
          <div className={classes.item} key={index}>
            <TextField
              multiline
              maxRows={4}
              name={column.field}
              value={fieldValue}
              onChange={handleChange}
              variant="outlined"
              label={column.headerName}
            />
          </div>
        )
        return []
      }

      let displayValue = fieldValue || typeof fieldValue === 'boolean' ? fieldValue.toString() : ''
      /* Key is user object */
      if (fieldValue?.username) displayValue = fieldValue.username
      /* Takes flashed boolean value and translates it to english   */
      if (column.field === 'flashed') displayValue = fieldValue ? 'YES' : 'NO'
      if (column.field === 'flashed' && fieldValue === null) displayValue = ''

      return (
        <Typography key={index} variant="h6" className={classes.item}>
          <Typography variant="subtitle1" component="span" className={classes.itemType}>
            {column.headerName}:
          </Typography>
          {displayValue}
        </Typography>
      )
    })

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card elevation={2} className={classes.card}>
        <CardContent className={classes.cardContent}>
          {isSelectedAndSequence ? (
            <Typography variant="h4" className={`${classes.item} ${classes.mainItem}`}>
              {'New sequence'}
            </Typography>
          ) : (
            <Typography variant="h4" className={`${classes.item} ${classes.mainItem}`}>
              <Typography variant="subtitle1" component="span" className={classes.mainItemType}>
                Flash sequence ID:
              </Typography>
              {inputData.sequence_id}
            </Typography>
          )}

          {modalSequenceDisplayOnlyClickedRender}
          {modalClickedEditableRender}
          {sequenceId && (
            <div className={`${classes.item} ${classes.mainItem}`}>
              <TextField
                multiline
                name="sequenceSteps"
                value={JSON.stringify(inputData.steps, null, 4)}
                onChange={handleChange}
                variant="outlined"
                label="Steps"
                className={classes.mainItem}
                disabled
              />
            </div>
          )}
        </CardContent>

        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            className={classes.button}
            color="secondary"
            disabled={!edited}
            onClick={handleDialog}
          >
            {isSelectedAndSequence ? 'Save as new sequence' : 'Save changes'}
          </Button>
          {sequenceId && (
            <Button variant="contained" className={classes.button} onClick={handleSequenceDownload} disabled={!isAdmin}>
              Download sequence
            </Button>
          )}
        </div>

        {isDialogOpen && (
          <ConfirmDialog
            isDialogOpen={isDialogOpen}
            handleDialog={handleDialog}
            title={`Do you want to ${isSelectedAndSequence ? 'save changes as new sequence' : 'save changes'}?`}
          >
            <Button onClick={handleDialog} color="default">
              No
            </Button>
            <Button onClick={handleEditSelected} color="primary" autoFocus>
              Yes
            </Button>
          </ConfirmDialog>
        )}

        <Cancel onClick={handleModal} className={classes.closeIcon} />
      </Card>
    </Modal>
  )
}

RowDetailModal.propTypes = {
  clickedRowIndex: PropTypes.number,
  isModalOpen: PropTypes.bool,
  handleModal: PropTypes.func,
  sequenceId: PropTypes.string,
  setUpdatedItemIndex: PropTypes.func,
}

export default RowDetailModal
