import React, { useState } from 'react'

import RowDetailModal from '../modals/RowDetailModal'
import DataGridTable from './DataGridTable'

import useStyles from '../../styles/components/DataTableStyle'

const DataTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clickedSequenceId, setClickedSequenceId] = useState('')
  const [clickedRowIndex, setClickedRowIndex] = useState(null)
  const [updatedItemIndex, setUpdatedItemIndex] = useState([])

  const classes = useStyles()

  const handleModal = () => {
    if (isModalOpen) {
      setClickedSequenceId('')
      setClickedRowIndex(null)
    }
    setIsModalOpen(!isModalOpen)
  }

  return (
    <section className={classes.root}>
      <DataGridTable
        handleModal={handleModal}
        setClickedSequenceId={setClickedSequenceId}
        setClickedRowIndex={setClickedRowIndex}
        updatedItemIndex={updatedItemIndex}
      />

      {isModalOpen && (
        <RowDetailModal
          clickedRowIndex={clickedRowIndex}
          isModalOpen={isModalOpen}
          handleModal={handleModal}
          sequenceId={clickedSequenceId}
          setUpdatedItemIndex={setUpdatedItemIndex}
        />
      )}
    </section>
  )
}

export default DataTable
