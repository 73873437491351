import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { OutlinedInput, InputLabel, FormControl, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'

const PasswordInput = ({
  handleChange,
  name,
  value,
  label = 'Password',
  parentStyle,
  errorMessage = '',
  autocomplete = true,
  ...props
}) => {
  const [isVisiblePassword, setIsVisiblePassowrd] = useState(false)

  const handlePasswordVisibility = () => {
    setIsVisiblePassowrd(!isVisiblePassword)
  }

  return (
    <FormControl variant="outlined" className={parentStyle?.formControl} {...props}>
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        label={label}
        variant="outlined"
        onChange={handleChange}
        value={value}
        name={name}
        type={isVisiblePassword ? 'text' : 'password'}
        error={errorMessage !== ''}
        inputProps={{
          autoComplete: autocomplete ? 'password' : 'new-password',
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="passwordVisibility" onClick={handlePasswordVisibility} edge="end" tabIndex="-1">
              {isVisiblePassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {errorMessage.password !== '' && (
        <FormHelperText className={parentStyle?.errorMessage} aria-label="passwordValidation">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

PasswordInput.propTypes = {
  handleChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  parentStyle: PropTypes.object,
  autocomplete: PropTypes.bool,
}

export default PasswordInput
