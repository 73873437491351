import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const AutoCompleteFilterInput = ({
  filter,
  options,
  selectedValues,
  handleSelectedValues,
  filterWidthClass,
  ...props
}) => {
  const defaultValue = selectedValues[filter.field] ? selectedValues[filter.field] : ''
  const [inputValue, setInputValue] = useState(defaultValue)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setInputValue(defaultValue)
    setValue(defaultValue)
  }, [defaultValue])

  const safeOptions = options[filter.field]
    ? options[filter.field].map((option) => (option === null ? 'null' : option))
    : []

  return (
    <Autocomplete
      options={safeOptions}
      getOptionLabel={(option) => option.toString()}
      getOptionSelected={(option, value) => option === value}
      name={filter.field}
      className={filterWidthClass}
      value={value ? value : null}
      onChange={(event, newValue, reason) => {
        handleSelectedValues(event, { [filter.field]: newValue }, reason)
        if (reason === 'clear') setValue('')
        setValue(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField label={filter.headerName} name={filter.field} variant="outlined" {...params} />
      )}
      {...props}
    />
  )
}

AutoCompleteFilterInput.propTypes = {
  filter: PropTypes.object,
  filterWidthClass: PropTypes.string,
  options: PropTypes.object,
  selectedValues: PropTypes.object,
  handleSelectedValues: PropTypes.func,
}

export default AutoCompleteFilterInput
