import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { TextField, InputAdornment } from '@material-ui/core'
import Search from '@material-ui/icons/Search'

const SearchFieldFilterInput = ({ filter, filterWidthClass, selectedValues, handleSelectedValues }) => {
  const inputValue = selectedValues[filter.field] ? selectedValues[filter.field] : ''
  const [searchTerm, setSearchTerm] = useState(inputValue)

  useEffect(() => {
    setSearchTerm(inputValue)
  }, [inputValue])

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchTerm !== '' && handleSelectedValues(null, { [filter.field]: searchTerm })
    }, 1000)

    if (searchTerm === '') {
      handleSelectedValues(null, { [filter.field]: searchTerm })
    }

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <TextField
      label={filter.headerName}
      name={filter.field}
      value={searchTerm}
      type="search"
      variant="outlined"
      className={filterWidthClass}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )
}

SearchFieldFilterInput.propTypes = {
  filter: PropTypes.object,
  filterWidthClass: PropTypes.string,
  selectedValues: PropTypes.object,
  handleSelectedValues: PropTypes.func,
}

export default SearchFieldFilterInput
