import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    top: '-1rem',
    left: '-1rem',
    zIndex: '99',
    marginLeft: '0.6rem',
    '& svg ~ span': {
      marginLeft: '0.6rem',
    },
  },
})

export default useStyles
