import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  addNewButton: {
    textAlign: 'center !important',
  },
  column: {
    width: 'calc(100% / 3)',
    minWidth: '10rem',
  },
})

export default useStyles
