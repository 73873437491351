import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cell: {
    position: 'relative',
    width: 'calc(100vw/3)',
    background: 'white',
  },
  nameInput: {
    width: '90%',
    '& input': {
      textAlign: 'center',
    },
  },
  hiddenIcon: {
    visibility: 'hidden',
  },
  tableCellEdit: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  dragAndDropIcon: {
    position: 'absolute',
    top: '50%',
    left: '2rem',
    transform: 'translateY(-50%)',
  },
})

export default useStyles
