/* Sets CSS variable to get width of viewport with/without scrollbar */
export const setViewportWidth = () => {
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  let scrollbarWidth = !isMobileDevice ? window.innerWidth - document.body.clientWidth : 0
  document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`)
}

export const setColumnCount = (count) => {
  localStorage.setItem('columnCount', count)
  document.documentElement.style.setProperty('--columnCount', count)
}

export const getColumnCount = () => {
  const columnCount = localStorage.getItem('columnCount') ? localStorage.getItem('columnCount') : 3
  setColumnCount(columnCount)

  return parseInt(columnCount)
}
