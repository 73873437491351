export const getSessionFilters = () => {
  return JSON.parse(sessionStorage.getItem('filters'))
}

export const saveSessionFilters = (selectedFilters) => {
  sessionStorage.setItem('filters', JSON.stringify(selectedFilters))
}

export const removeSessionFilters = () => {
  sessionStorage.removeItem('filters')
}
