import client from './axios'

const usersApi = {
  roles: () => {
    return client.get('user/roles/')
  },

  list: (page) => {
    const config = { params: { page: page } }
    return client.get('user/', page != null ? config : undefined)
  },

  add: (username, password, role_id) => {
    return client.post('user/register/', { username, password, role_id })
  },

  changePassword: (userId, password) => {
    return client.put(`user/${userId}/update-password`, { password })
  },

  delete: (userId) => {
    return client.delete(`user/${userId}/delete`)
  },
}

export default usersApi
