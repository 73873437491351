import { useContext } from 'react'
import { AppSettingsContext } from '../App'

const useHandleError = () => {
  const { setSystemMessage } = useContext(AppSettingsContext)

  const handleError = (error) => {
    setSystemMessage(error.message)
  }
  return handleError
}

export default useHandleError
