import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import EditIcon from '@material-ui/icons/Edit'

import InfoTile from '../components/tiles/InfoTile'
import LinkButton from '../components/LinkButton'
import { tilesReloadTimer as TILES_RELOAD_TIMER } from '../config/config.json'
import useRole from '../useUserRole'

import useStyle from '../styles/pages/StatisticsViewPageStyle'
import statisticsApi from '../api/statistics'
import useHandleError from '../api/useHandleError'
import { getColumnCount, setColumnCount } from '../useViewPort'

const StatisticsViewPage = () => {
  const { id } = useParams()
  const { userRole } = useRole()
  const [infoTile, setTileInfo] = useState([])
  const [columnsPerRow, setColumnsPerRow] = useState(getColumnCount())
  const classes = useStyle()
  const handleError = useHandleError()

  const getTileInfo = () => {
    return statisticsApi
      .getTile(id)
      .then((data) => data.data)
      .catch(handleError)
  }

  useEffect(() => {
    let ignore = false
    let getTilesInterval

    const fetchData = async () => {
      if (!ignore) {
        setTileInfo(await getTileInfo())
        getTilesInterval = setInterval(async () => {
          setTileInfo(await getTileInfo())
        }, TILES_RELOAD_TIMER)
      }
    }

    fetchData()

    return () => {
      clearInterval(getTilesInterval)
      ignore = true
    }
  }, [])

  const handleCountChange = (e) => {
    const { value } = e.target

    const correctValue = value > 0 ? value : 1

    setColumnsPerRow(correctValue)
    setColumnCount(correctValue)
  }

  const renderInfoTiles =
    infoTile?.rows && infoTile.rows.map((tile, index) => <InfoTile key={index} title={tile.name} value={tile.count} />)

  return (
    <main className={classes.root}>
      <LinkButton url={'/statistics/'} icon={<ArrowBackIcon />} aria-label="goBackButton" />
      {userRole === 'admin' && <LinkButton url={`/statistics/edit/${id}`} icon={<EditIcon />} />}
      <Typography variant="h3" component="h1" className={classes.tileTitle}>
        {infoTile.name}

        <FormControl className={classes.columnsCount}>
          <InputLabel>Columns</InputLabel>

          <Select value={columnsPerRow} label="Columns" onChange={handleCountChange} aria-label="columnsCount">
            {Array.from(Array(10).keys()).map((value) => (
              <MenuItem value={value + 1} key={value}>
                {value + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Typography>
      <section className={classes.tilesGrid}>{renderInfoTiles}</section>
    </main>
  )
}

StatisticsViewPage.propTypes = {
  infoTiles: PropTypes.array,
}

export default StatisticsViewPage
