import React, { useEffect, useState, useContext } from 'react'

import { useDispatch } from 'react-redux'

import { Box, Button, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'

import { RowsContext } from '../../pages/TablePage'
import config from '../../config/config'
import ConfirmDialog from '../dialogs/ConfirmDialog'

import useRole from '../../useUserRole'
import useStyle from '../../styles/components/TableActionsInputStyle'
import tableApi from '../../api/table'
import { setSystemMessage } from '../../features/systemMessageSlice'

import { removeSessionFilters } from '../../useSessionFilters'

const TableActionsInput = () => {
  const { userRole } = useRole()
  const { selectedRows, filters, maxRowsCount, getNewData, setFilters } = useContext(RowsContext)
  const defaultExportValue = config.SAS.intervals[0].valueInMinutes

  const [exportInterval, setExportInterval] = useState(defaultExportValue)
  const classes = useStyle()

  const dispatch = useDispatch()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const exportCount = (sas = false) => {
    if (selectedRows.size !== 0) return `selected (${selectedRows.size})`
    if (Object.keys(filters).length !== 0) return `Filtered (${maxRowsCount})`
    return sas ? '(Rules)' : '(All)'
  }

  const getIntervalSettings = () => {
    tableApi.getSettings().then((res) => {
      setExportInterval(res.data.send_to_SAS_interval)
    })
  }

  useEffect(() => {
    getIntervalSettings()
  }, [])

  const handleIntervalChange = (e) => {
    setExportInterval(e.target.value)
    tableApi
      .schedulerUpdate(e.target.value)
      .then((res) => {
        res.status && dispatch(setSystemMessage({ message: `Interval was successfully changed`, severity: 'success' }))
      })
      .catch((error) => {
        dispatch(setSystemMessage({ message: `Interval change failed with error ${error.message}`, severity: 'error' }))
      })
  }

  const handleExport = async () => {
    await dispatch(setSystemMessage({ message: `CSV export has started`, severity: 'success' }))
    await tableApi
      .export([...selectedRows], filters)
      .then((res) => {
        const blob = new Blob([res.data], { type: 'data:text/csvcharset=utf-8,' })
        const blobURL = window.URL.createObjectURL(blob)
        let anchor = document.createElement('a')
        anchor.download = `export_table_${new Date().toJSON().slice(0, 10)}.csv`
        anchor.href = blobURL
        anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':')
        anchor.click()
      })
      .catch((error) => {
        dispatch(setSystemMessage({ message: `Export failed with error ${error.message}`, severity: 'error' }))
      })
  }

  const handleDialog = () => {
    setIsDialogOpen(!isDialogOpen)
  }

  const shouldExportDialogOpen =
    (selectedRows.size >= 100 || (selectedRows.size === 0 && maxRowsCount >= 100)) && exportCount(true) !== '(Rules)'

  const handleExportDialog = () => {
    if (shouldExportDialogOpen) {
      handleDialog()
      return
    }

    handleExportSAS()
  }

  const handleExportSAS = async () => {
    if (shouldExportDialogOpen) handleDialog()

    await tableApi
      .exportSAS([...selectedRows], filters)
      .then((res) => {
        res.status &&
          dispatch(setSystemMessage({ message: `Export to SAS was successfully executed`, severity: 'success' }))
      })
      .catch((error) => {
        dispatch(setSystemMessage({ message: `Export to SAS failed with error ${error.message}`, severity: 'error' }))
      })
  }

  const handleFiltersClear = () => {
    setFilters({})
    removeSessionFilters()
  }

  const RenderMenuItems = config.SAS.intervals.map((interval, index) => {
    return (
      <MenuItem key={index} value={interval.valueInMinutes}>
        {interval.displayName}
      </MenuItem>
    )
  })

  const isAdmin = userRole === 'admin'

  return (
    <>
      <Box className={`${classes.root} sticky-vertical`}>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            className={classes.item}
            onClick={handleExportDialog}
            disabled={!isAdmin}
          >
            Export to SAS {exportCount(true)}
          </Button>
        </div>

        <FormControl variant="outlined" className={`${classes.item} ${classes.exportPicker}`} disabled={!isAdmin}>
          <InputLabel id="export-label">Export to SAS every</InputLabel>
          <Select
            labelId="export-label"
            value={exportInterval}
            onChange={handleIntervalChange}
            label="Export to SAS every:"
          >
            {RenderMenuItems}
          </Select>
        </FormControl>

        <div className={classes.buttonGroup}>
          <Button variant="contained" color="primary" className={classes.item} onClick={() => getNewData()}>
            Refresh data
          </Button>
          <Button variant="contained" color="primary" className={classes.item} onClick={handleFiltersClear}>
            Clear filters
          </Button>
          <Button variant="contained" color="primary" className={classes.item} onClick={handleExport}>
            Export {exportCount()}
          </Button>
        </div>
      </Box>
      <ConfirmDialog
        isDialogOpen={isDialogOpen}
        title={`${exportCount(true)} records will be sent to SAS.`}
        description="Are you sure?"
        handleDialog={handleDialog}
      >
        <Button onClick={handleDialog} color="default">
          Cancel
        </Button>
        <Button onClick={handleExportSAS} color="primary" autoFocus>
          Confirm
        </Button>
      </ConfirmDialog>
    </>
  )
}

export default TableActionsInput
