import React from 'react'

import { setViewportWidth } from '../useViewPort'

import LoginForm from '../components/forms/LoginForm'
import gordonLogoUrl from '../images/gordon_logo.svg'

import useStyles from '../styles/pages/LoginPageStyle'

const LoginPage = () => {
  const classes = useStyles()
  setTimeout(() => setViewportWidth())

  return (
    <main className={classes.main}>
      <img src={gordonLogoUrl} className={classes.logo} />
      <LoginForm />
    </main>
  )
}

export default LoginPage
