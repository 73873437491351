import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  main: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    width: 'var(--viewportWidth)',
    height: '100%',
    minHeight: 'calc(100vh - 12rem)',
  },
  logo: {
    marginBottom: '3rem',
    height: 'clamp(3rem, 8vw,6rem)',
  },
})

export default useStyles
