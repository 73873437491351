import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormHelperText,
  Select,
} from '@material-ui/core'

import PasswordInput from '../inputs/PasswordInput'

import useStyles from '../../styles/components/UserDialogStyle'
import { setUserRoles, setGordonUsers, addUser, addGordonUser, setGordonRoles } from '../../features/usersSlice'
import { setSystemMessage } from '../../features/systemMessageSlice'
import usersApi from '../../api/users'
import gordonUsersApi from '../../api/gordonUsers'

const emptyValues = { username: '', password: '', confirmPassword: '', role_id: '' }

const UserDialog = ({ isDialogOpen, setIsDialogOpen, action, isGordonUser }) => {
  const [values, setValues] = useState({ ...emptyValues })
  const [errors, setErrors] = useState({ ...emptyValues })

  const roles = useSelector((state) => (isGordonUser ? state.users.gordonRoles : state.users.roles))
  const users = useSelector((state) => (isGordonUser ? state.users.gordonValue : state.users.value))
  const selectedUser = useSelector((state) => state.users.selectedUser)

  const dispatch = useDispatch()
  const classes = useStyles()

  const dataTypeApi = isGordonUser ? gordonUsersApi : usersApi
  const isPasswordChangeAction = action === 'passwordChange'
  const userPrefix = isGordonUser ? 'gordon user' : 'user'

  const fetchUserRoles = async () => {
    await dataTypeApi
      .roles()
      .then((res) => {
        if(isGordonUser){
          dispatch(setGordonRoles(res.data.results))
        }else {
          dispatch(setUserRoles(res.data.results))
        }
      })
      .catch((error) => {
        dispatch(setSystemMessage({ severity: 'error', message: `Unable to get roles: ${error.message}` }))
      })
  }

  useEffect(() => {
    fetchUserRoles()
  }, [])

  useEffect(() => {
    isPasswordChangeAction &&
      setValues({
        ...emptyValues,
        username: selectedUser.username,
        role_id: selectedUser.role != null ? selectedUser.role.id : -1,
      })
  }, [selectedUser])

  const addCorrectUserType = (newUser) => {
    isGordonUser ? dispatch(addGordonUser(newUser)) : dispatch(addUser(newUser))
  }

  const handleClose = () => {
    setIsDialogOpen(!isDialogOpen)
    setValues(emptyValues)
    setErrors(emptyValues)
  }

  const handleAddUser = async () => {
    await dataTypeApi
      .add(values.username, values.password, values.role_id)
      .then((res) => {
        addCorrectUserType(res.data)
      })
      .catch((error) => {
        useDispatch(
          setSystemMessage({
            severity: 'error',
            message: `Users data fetching has failed with error: ${error.message}`,
          })
        )
      })
  }

  const handleEditUser = async () => {
    return isGordonUser
      ? await gordonUsersApi.edit(selectedUser.id, values.username, values.password, values.role_id)
      : await usersApi.changePassword(selectedUser.id, values.password)
  }

  const updateGordonUser = (user) => {
    // find user and updated information in users state
    const index = users.findIndex((user) => user.id === selectedUser.id)
    let updatedUsers = [...users]
    updatedUsers[index] = { ...user }
    dispatch(setGordonUsers(updatedUsers))
  }

  const handleConfirm = async () => {
    let newErrors = {}

    for (const field of Object.keys(values)) {
      newErrors[field] = validateField(field, values[field])
    }

    setErrors(newErrors)

    if (!isAnyInputInvalid(newErrors)) {
      const apiAction = isPasswordChangeAction ? handleEditUser : handleAddUser

      await apiAction()
        .then((res) => {
          if (isGordonUser && isPasswordChangeAction) updateGordonUser(res.data)
          handleClose()
          dispatch(
            setSystemMessage({
              severity: 'success',
              message: isPasswordChangeAction
                ? `Password was changed for user "${values.username}" `
                : `User "${values.username}" was successfully added`,
            })
          )
        })
        .catch(() => {
          dispatch(
            setSystemMessage({
              severity: 'error',
              message: 'User already exists',
            })
          )
        })
    }
  }

  const validateField = (name, value) => {
    switch (name) {
      case 'username':
        return /^[\w.@+-]{1,150}$/.test(value)
          ? ''
          : 'Must be 1 - 150 characters long and contain only letters, digits or @ . + - _'
      case 'password':
        return value !== '' ? '' : 'Password must be at least 1 character long'
      case 'confirmPassword':
        return value !== values.password ? 'Passwords must match' : ''
      case 'role_id':
        return value !== '' ? '' : 'Role is required'
    }
  }

  const validateInput = (name, value) => {
    setErrors({
      ...errors,
      [name]: validateField(name, value),
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value,
    })

    validateInput(name, value)
  }

  const isAnyInputInvalid = (errorsObject) => {
    // returns true if there is any error (any attribute in 'errors' is truthy), otherwise returns false
    return !Object.keys(errorsObject).every((e) => !errorsObject[e])
  }

  return (
    <Dialog open={isDialogOpen} onClose={handleClose} className={classes.root}>
      <DialogTitle>
        {isPasswordChangeAction ? `Change password for "${selectedUser.username}"` : `New ${userPrefix}`}
      </DialogTitle>
      <DialogContent>
        {(!isPasswordChangeAction || isGordonUser) && (
          <FormControl required variant="outlined" className={classes.formControl}>
            <TextField
              error={errors.username !== ''}
              value={values.username}
              onChange={handleChange}
              name="username"
              variant="outlined"
              autoFocus
              label="Username"
              type="text"
              aria-label="usernameInput"
              inputProps={{
                autoComplete: 'off',
              }}
            />
            {errors.username !== '' && (
              <FormHelperText className={classes.errorMessage}>{errors.username}</FormHelperText>
            )}
          </FormControl>
        )}
        <PasswordInput
          parentStyle={classes}
          name="password"
          value={values.password}
          handleChange={handleChange}
          errorMessage={errors.password}
          autocomplete={false}
          aria-label="passwordInput"
        />
        <PasswordInput
          parentStyle={classes}
          name="confirmPassword"
          value={values.confirmPassword}
          handleChange={handleChange}
          label="Confirm Password"
          errorMessage={errors.confirmPassword}
          autocomplete={false}
          aria-label="confirmPasswordInput"
        />
        {!isPasswordChangeAction && roles && (
          <FormControl required className={classes.formControl}>
            <Select
              native
              name={'role_id'}
              value={values.role_id}
              onChange={handleChange}
              variant="outlined"
              error={errors.role_id !== ''}
              aria-label="roleSelectInput"
            >
              <option value="" disabled>
                Select Role
              </option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Select>
            {errors.role_id !== '' && (
              <FormHelperText className={classes.errorMessage}>{errors.role_id}</FormHelperText>
            )}
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          disabled={isAnyInputInvalid(errors)}
          variant="contained"
          color="primary"
          aria-label="userDialogConfirmButton"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func,
  action: PropTypes.string,
  isGordonUser: PropTypes.bool,
}

export default UserDialog
