import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '20rem',
  },
  cardContent: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    boxSizing: 'border-box',
    padding: '2.2rem',
    textAlign: 'center',
    '& h2': {
      fontSize: '1.8rem',
      marginBottom: '0.8rem',
    },
    '& p': {
      fontSize: '4.2rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
}))

export default useStyles
