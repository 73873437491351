import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    isOpen: false,
    severity: 'error',
    message: 'Error has occured',
  },
}

export const systemMessageSlice = createSlice({
  name: 'systemMessage',
  initialState,
  reducers: {
    openSystemMessage: (state) => {
      state.value.isOpen = true
    },
    closeSystemMessage: (state) => {
      state.value.isOpen = false
    },
    setSystemMessage: (state, action) => {
      state.value = { ...state.value, ...action.payload, isOpen: true }
    },
  },
})

export const { openSystemMessage, closeSystemMessage, setSystemMessage } = systemMessageSlice.actions

export default systemMessageSlice.reducer
