import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    padding: '1.5rem',
  },
  wideInput: {
    margin: '1rem',
    width: 'calc(100% / 6 * 2 - 2rem)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  input: {
    display: 'inline-flex',
    margin: '1rem',
    width: 'calc(100% / 6 - 2rem)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 2rem)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  switch: {
    justifyContent: 'center',
    margin: '1rem',
    width: 'calc(100% / 6 - 2rem)',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 2rem)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  showFilters: {
    width: '100%',
  },
  collapse: {
    width: '100%',
  },
  dateIcon: {
    zIndex: '-1',
  },
}))

export default useStyles
