import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 5rem - 5rem)',
    padding: '1.6rem',
    background: 'rgba(0,0,0,0.05)',
  },
  title: {
    marginBottom: '2.4rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  },
  tilesGrid: {
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, minmax(30rem, 1fr))',
    gridGap: '1.6rem',
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))',
    },
  },
}))

export default useStyles
