import history from './history'

export const setToken = (userToken) => {
  sessionStorage.setItem('token', JSON.stringify(userToken))
}

export const getToken = () => {
  const tokenString = sessionStorage.getItem('token')
  const userToken = JSON.parse(tokenString)
  return userToken
}

export const removeToken = () => {
  sessionStorage.removeItem('token')
  history.push('/')
}

export const setRefreshToken = (refreshToken) => {
  sessionStorage.setItem('refreshToken', JSON.stringify(refreshToken))
}

export const getRefreshToken = () => {
  const tokenString = sessionStorage.getItem('refreshToken')
  return JSON.parse(tokenString)
}
