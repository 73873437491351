import React from 'react'

import gordonLogoUrl from '../images/gordon_logo.svg'

import useStyle from '../styles/components/FooterStyle'

const Footer = () => {
  const classes = useStyle()

  return (
    <footer className={`${classes.root} sticky-vertical`}>
      <img src={gordonLogoUrl} className={classes.logo} />
    </footer>
  )
}

export default Footer
