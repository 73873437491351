import React from 'react'

import PropTypes from 'prop-types'

import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'

const SelectFilterInput = ({
  filter,
  filterWidthClass,
  stylingClass,
  selectedValues,
  handleSelectedValues,
  options,
  hasNullValue = true,
  ...props
}) => {
  const mergedOptions = { ...options.report_filters, ...options.sequence_filters }

  const renderSelectOptions = () => {
    const fieldType = filter.field === 'work_type' ? 'work_type_choices' : 'result_choices'

    const optionsToRender = filter.options ? filter.options : mergedOptions[fieldType]

    return (
      optionsToRender &&
      Object.entries(optionsToRender).map((option, index) => {
        const [key, value] = option
        return (
          <MenuItem key={index} value={value}>
            {key}
          </MenuItem>
        )
      })
    )
  }

  return (
    <FormControl className={`${filterWidthClass} ${stylingClass}`} variant="outlined" {...props}>
      <InputLabel>{filter.headerName}</InputLabel>

      <Select
        label={filter.headerName}
        name={filter.field}
        value={selectedValues[filter.field]?.toString() ? selectedValues[filter.field] : ''}
        onChange={(e) => handleSelectedValues(e)}
      >
        {hasNullValue && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}

        {renderSelectOptions()}
      </Select>
    </FormControl>
  )
}

SelectFilterInput.propTypes = {
  filter: PropTypes.object,
  filterWidthClass: PropTypes.string,
  stylingClass: PropTypes.object,
  selectedValues: PropTypes.object,
  handleSelectedValues: PropTypes.func,
  options: PropTypes.object,
  hasNullValue: PropTypes.bool,
}

export default SelectFilterInput
