import React from 'react'

import PropTypes from 'prop-types'

import { Card, CardContent, Typography } from '@material-ui/core'

import useStyle from '../../styles/components/InfoTileStyle'

const InfoTile = ({ title, value }) => {
  const classes = useStyle()

  return (
    <Card className={classes.root} elevation={3}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="h3" component="p">
          {value}
        </Typography>
      </CardContent>
    </Card>
  )
}

InfoTile.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
}

export default InfoTile
