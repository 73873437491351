import React, { useState, useMemo } from 'react'

import PropTypes from 'prop-types'

import { TableCell, TableRow, Checkbox } from '@material-ui/core'

import useStyle from '../../../styles/components/TableGridRowStyle'

const TableGridRow = ({ handleCellClick, index, row, lastRowElementReft, handleRowSelection }) => {
  const [isChecked, setIsChecked] = useState(false)
  const classes = useStyle()
  const classNames = isChecked ? `${classes.tableCell} ${isChecked && classes.tableCellChecked}` : classes.tableCell

  const handleSelection = (e) => {
    setIsChecked(!isChecked)
    handleRowSelection(e)
  }

  const handleCell = (e) => {
    const { name } = e.currentTarget.dataset

    if (name !== 'date' && name !== 'sequence_id' && name !== 'custom_comment') {
      handleSelection(e)
    } else {
      handleCellClick(e)
    }
  }

  const renderCells = (row, rowIndex) => {
    return Object.entries(row).map((item, index) => {
      const [key, value] = item
      /*
      User is stored as object, so it has to be specific. 
      We dont want to have ID and steps in table so we skip them.
      At last we cant render object or null value so we have skip them too.
      */
      if (key === 'user' || (key !== 'steps' && key !== 'id' && (typeof value !== 'object' || value === null))) {
        let displayValue = value ? value.toString() : ''
        /* Key is user object */
        if (value?.username) displayValue = value.username
        /* Converts boolean to string */
        if (typeof value === 'boolean') displayValue = value.toString()
        if (key === 'flashed')
          /* Takes flashed boolean value and translates it to english   */
          displayValue = value ? 'YES' : 'NO'
        if (key === 'flashed' && value === null) displayValue = ''

        return (
          <TableCell
            key={index}
            className={classNames}
            align="center"
            data-index={rowIndex}
            data-sequence={key === 'sequence_id' ? value : null}
            data-name={key}
            onClick={handleCell}
          >
            {displayValue}
          </TableCell>
        )
      }

      return []
    })
  }

  const rowData = useMemo(() => renderCells(row, index), [JSON.stringify(row), isChecked])

  return (
    <TableRow ref={lastRowElementReft}>
      <TableCell align="center" className={classNames}>
        <Checkbox onChange={handleSelection} checked={isChecked} name={`${row.id}`} color="primary" />
      </TableCell>
      {rowData}
    </TableRow>
  )
}

TableGridRow.propTypes = {
  handleCellClick: PropTypes.func,
  index: PropTypes.number,
  row: PropTypes.object,
  lastRowElementReft: PropTypes.func,
  handleRowSelection: PropTypes.func,
}

export default TableGridRow
