import React from 'react'

import PropTypes from 'prop-types'

import { FormControlLabel, FormControl, Switch } from '@material-ui/core'

const BooleanFilterInput = ({ filter, classes, selectedValues, handleSelectedValues, filterWidthClass }) => {
  const switchValue = selectedValues[filter.field] ? true : false

  return (
    <FormControl className={`${filterWidthClass} ${classes.switch}`} variant="outlined">
      <FormControlLabel
        control={
          <Switch
            color="primary"
            name={filter.field}
            value={switchValue}
            checked={switchValue}
            onChange={(event) => {
              handleSelectedValues(event, { [filter.field]: event.target.checked }, switchValue ? 'clear' : undefined)
            }}
          />
        }
        labelPlacement="top"
        label={filter.headerName}
      />
    </FormControl>
  )
}

BooleanFilterInput.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,
  selectedValues: PropTypes.object,
  handleSelectedValues: PropTypes.func,
  filterWidthClass: PropTypes.string,
}

export default BooleanFilterInput
