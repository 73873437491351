import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '0 0.8rem',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:nth-last-of-type(-n+2)': {
      whiteSpace: 'unset',
    },
  },
  tableCellChecked: {
    background: alpha(theme.palette.primary.light, 0.15),
  },
}))

export default useStyles
