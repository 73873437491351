import axios from 'axios'
import config from '../config/config'
import { getToken, getRefreshToken, setToken } from '../useToken'
import history from '../history'

const baseURL = config.ApiUrl
export const nonInterceptedClient = axios.create({ baseURL })
const client = axios.create({ baseURL })

const redirectToLogin = () => {
  sessionStorage.clear()
  history.push('/login')
}

client.interceptors.request.use((config) => {
  const token = getToken()
  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

client.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (originalRequest._retry) {
      // the request has been retried and failed again
      redirectToLogin()
    } else if (error.response != null) {
      const refreshToken = getRefreshToken()
      switch (error?.response.status) {
        case 401:
          if (refreshToken == null) {
            redirectToLogin()
          } else {
            originalRequest._retry = true // flag indicating retried request - used to prevent infinite recursion
            await axios
              .post(`${baseURL}/token/refresh`, { refresh: refreshToken })
              .then((res) => {
                const token = res?.data.access
                if (token) {
                  setToken(token)
                }
              })
              .catch((refreshError) => {
                redirectToLogin()
                return Promise.reject(refreshError)
              })
            return client(originalRequest) // retrying original request with refreshed token
          }
          break
        case 403:
          redirectToLogin()
          return
      }
    }
    return Promise.reject(error)
  }
)

export default client
