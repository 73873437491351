import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogTitle-root, & .MuiDialogActions-root': {
      padding: '0.8rem 2.3rem',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'unset',
      padding: '0 1.rem',
    },
    '& .MuiPaper-root': {
      padding: '0.6rem 0rem',
    },
    '& .MuiInputBase-root': {
      marginRight: '0.8rem',
    },
  },
  formControl: {
    margin: 5,
    width: '100%',
  },
  dialogButtonsCentered: {
    justifyContent: 'center',
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}))

export default useStyles
